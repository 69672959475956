<template>
  <div class="relative">
    <phoneContainer>
      <MobileNavbar></MobileNavbar>
      <div class="pt-[24px] w-full mt-[13px]">
        <div
          class="w-[calc(20%+32px)] h-[4px] bg-[var(--blue)] rounded mx-[-16px]"
        ></div>
      </div>
      <div class="flex justify-between mt-[16px] px-[13px]">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-[25px] text-blue-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </div>
        <div class="font-bold text-[16px]">Personal details</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div class="px-[13px] py-[10px] top-[8px]">
        <p class="text-[24px] font-bold">Provide details of your employment</p>
        <div
          id="container"
          class="gap-[24px] grid mt-[15px]"
          v-for="(item, idx) in data"
          :key="item.id"
        >
          <div>
        
            <inputForm label="Exchange name" :inputModel="item.exchangeName"   @inputModel:update="(res)=>{item.exchangeName = res}" ></inputForm>
          </div>
          <div>
            <inputForm label="Role/position" :inputModel="item.role" @inputModel:update="(res)=>{item.role = res}"></inputForm>
          </div>
          <div v-if="data.length > 1" class="flex pt-[11px] justify-end">
            <button
              @click="() => addRemoveData(idx, 'remove')"
              class="text-[#FF1A40] text-[14px]"
            >
              Remove
            </button>
          </div>
        </div>
        <div class="py-[15px] border-t-[1px] mt-[15px]">
          <button
            @click="addRemoveData(null, 'add')"
            class="text-[var(--blue)] text-[14px]"
          >
            Add another exchange
          </button>
        </div>
        <div>
         <btnForm theme="1" :btn-disabled="isButtonDisabled" @click="router.push({name:'PersonalDetails_Provide'})">
            Continue
         </btnForm>
        </div>
      </div>
    </phoneContainer>
  </div>
</template>

<script setup>
import phoneContainer from "@/components/container/PhoneContainer.vue";
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import inputForm from "@/components/form/input-form.vue";
import { ref, computed  } from "vue";
import btnForm from "@/components/form/btn-form.vue";
import { useRoute, useRouter } from 'vue-router';
const router = useRouter();

const data = ref([{ id: 1, exchangeName: "", role: "" }]);

const addRemoveData = (idx, reqType) => {
  if (reqType === "add") {
    data.value.push({
      id: Date.now(), // Unique id for each entry
      exchangeName: "",
      role: "",
    });
  } else if (reqType === "remove") {
    data.value.splice(idx, 1);
  }
};

const isButtonDisabled = computed(() => {
  return data.value.length === 0 || data.value.some(item => item.exchangeName === "" || item.role === "");
});
</script>
