<template>
    <phoneContainer>
        <MobileNavbar></MobileNavbar>
        <div class="bg-white h-full flex items-center p-[16px] flex-col gap-[16px] overflow-y-[auto]">
            <div class="pt-[24px] w-full ">
                <div class="w-[calc(56%+32px)] h-[4px] bg-[var(--blue)] rounded mx-[-16px]"></div>
            </div>
            <div class="relative w-full ">
                <div class="absolute">
                    <BackNav class="relative !top-[0px] !m-[0px] !p-[0px] !pt-[0px] cursor-pointer" @click="router.push({name:'investmentexperience2'})"></BackNav>
                </div>
                <div class="w-full text-center font-bolder text-[16px]">Investment Experience</div>
            </div>
            <div class="w-full flex flex-col gap-[12px]">
                <div class="text-[24px] font-bolder">What do you want to invest in?</div>
                <div class="text-[14px] text-[#616068]">You can select multiple options. <span
                        class="text-[var(--blue)] cursor-pointer" @click="popUpModalElem._fn.show()">See
                        definitions</span></div>
            </div>
            <div class="w-full flex flex-col gap-[12px] grow-[1]">
                <label v-for="item in items"
                    class="w-full flex p-[24px] border-[2px] bg-[#F5F5F7] border-transparent rounded-[8px] justify-center items-center cursor-pointer"
                    :class="{'!border-[var(--blue)] !bg-[#B0B4FF29]':(item.value == 1)}">
                    <div class="grow-[1] text-[16px]">{{ item.name }}</div>
                    <div><input type="checkbox" :checked="item.value" @change="item.value = !item.value" class="accent-[var(--blue)] w-4 h-4 text-[var(--blue)] bg-[#F5F5F7] border-2 border-[var(--blue)]
                        outline-[var(--blue)] rounded focus:ring-[var(--blue)]"></div>
</label>


            </div>
            <div class="w-full pb-[12px]">
                <btnForm :set="isDisabled=computedItem" :disabled="isDisabled"
                    @click="router.push({name:'investmentexperience4'})">
                    <span v-if="isDisabled">Please select at least one option</span>
                    <span v-else>Continue</span>
                </btnForm>
            </div>

            <popupModal v-if="pageIsReady" ref="popUpModalElem">
                <template v-slot:body>
                    <div class="w-full flex flex-col gap-[24px]">
                        <div class="w-full flex flex-col gap-[4px]" v-for="item in definitions">
                            <div class="text-[#18171A] w-full text-[20px] font-bolder">{{ item.title }}</div>
                            <div class="w-full">{{ item.desc }}</div>
                        </div>
                    </div>
                </template>
            </popupModal>

        </div>
    </phoneContainer>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue'
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import popupModal from '@/components/mini_components/popupModal.vue';
import { useRouter } from 'vue-router';
import btnForm from "@/components/form/btn-form.vue";
const router = useRouter()
const pageIsReady = ref(false)
const popUpModalElem = ref(null)
const items = ref([
    {
        name: "Shares",
        value: 0
    },
    {
        name: "Sukuk & Bonds",
        value: 0
    },
    {
        name: "ETFs",
        value: 0
    },
    {
        name: "Real Estate Investment Trust (REITs)",
        value: 0
    },
    {
        name: "Futures",
        value: 0
    }

])


const definitions = ref([
    {
        title: "Shares",
        desc: "Also known as stocks, shares are one of the most well-known financial assets. They represent a unit of ownership in a company, meaning that you may be eligible to receive dividends and you may have a vote in some company decisions. Corporations issue stocks to raise money."
    },
    {
        title: "Futures",
        desc: `An agreement to buy or sell a specific commodity, asset, or security at a set future date for a price set on the day the agreement is made. Futures contracts, aka "futures," are traded on futures exchanges and require a brokerage account that's approved to trade futures.`
    },
    {
        title: "ETFs",
        desc: `Exchange-traded funds" are funds that act as baskets for certain sectors, industries, or regions. You can buy and sell ETFs during market hours. ETFs potentially lower your risk and exposure, while helping to diversify your portfolio.`
    },
    {
        title: "Real Estate Investment Trusts (REITs)",
        desc: "Funds that invest in real estate. Investors in REITs can efficiently access rental income streams from wide-ranging, underlying real estate assets without buying them or spending large amounts of capital."
    },
    {
        title: "Bonds",
        desc: "Investing in a bond means that you are a company's lender and the bond is a promise to pay back the money you've loaned, with interest. The amount of income a bond pays is largely determined by the prevailing interest rate at the time of issuance and other factors specific to that bond."
    },
])


const computedItem = computed({
    get() {
        let counts = items.value.filter(function (item) {
            return (item.value)
        })
        if (counts.length > 0) {
            return false
        }
        return true

    },
    set() {
        let counts = items.value.filter(function (item) {
            return (item.value)
        })
        if (counts.length > 0) {
            return false
        }
        return true

    }
})

onMounted(() => {
    pageIsReady.value = true
})
</script>