<template>
    <!-- <div class="flex flex-col gap-[24px]">
        <inputForm :label="'Full name'"></inputForm>
        <inputForm :label="'Email address'" type="email"></inputForm>
        <inputForm :label="'Password'" type="password"></inputForm>
        <btnForm>Create an account</btnForm>
    </div> -->

    <phoneContainer>
        <MobileNavbar></MobileNavbar>
        <div class="bg-white h-full flex justify-center items-center p-[16px] flex-col gap-[16px] overflow-y-[auto]">
            <BackNav class="relative !m-[0px] !p-[0px] !pt-[24px]"></BackNav>
            <div class="relative pt-[48px]">
                <div class="w-full pb-[24px]">
                    <headerForm>
                        <template #title>Review & confirm</template>
                        <template #subtitle>Please review the below details and confirm to proceed with the
                            verification. Your account will be created using these details.</template>
                    </headerForm>
                </div>
                <div class="mb-[16px] relative">
                    <inputForm :label="'Country of birth'" @click="handleOpenModal('country')" :inputModel="country">
                    </inputForm>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 1024 1024"
                        class="icon absolute top-[60%] right-[12px]" version="1.1">
                        <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                            fill="#3C70FF" />
                    </svg>
                </div>
                <div class="mb-[24px] relative">
                    <inputForm :label="'City of birth'" @click="handleOpenModal('city')" :inputModel="city"></inputForm>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 1024 1024"
                        class="icon absolute top-[60%] right-[12px]" version="1.1">
                        <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                            fill="#3C70FF" />
                    </svg>
                </div>
                <div class="mb-[16px]">
                    <region-select class="w-full select-region" v-model="region" :country="code" :region="region" />
                </div>
                <div
                    class="mb-[16px] bg-[#F5F5F7] text-black p-[16px] overflow-auto rounded-t-[8px] flex flex-col gap-3">
                    <div>
                        <p class="text-[#7C7A82] font-thin text-[14px]">ID</p>
                        784-1979-1234567-1
                    </div>
                    <div>
                        <p class="text-[#7C7A82] font-thin text-[14px]">Full name</p>
                        Hamad Salem Naser
                    </div>
                    <div>
                        <p class="text-[#7C7A82] font-thin text-[14px]">Date of birth</p>
                        27/08/1992
                    </div>
                    <div>
                        <p class="text-[#7C7A82] font-thin text-[14px]">Nationality</p>
                        United Arab Emirates
                    </div>
                    <div>
                        <p class="text-[#7C7A82] font-thin text-[14px]">Expiry date</p>
                        13/12/2025
                    </div>
                </div>
                <div class="w-full flex flex-col gap-2">
                    <btnForm @click="router.push({name:'confirmid'})">Looks good</btnForm>
                    <btnForm class="!border-[0px]" theme="2">
                        <div class="text-[#3C70FF]">Scan again</div>
                    </btnForm>
                </div>

            </div>
        </div>

        <RevModal v-if="isMounted" :forRegions="regions" :type="modalType" :show="showModal"
            :header="'Country of birth'" @modal:close="showModal = false" @select:country="handleSelectedCountry"
            @select:city="handleSelectedCity">
        </RevModal>
    </phoneContainer>

</template>

<script setup>
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import headerForm from '@/components/form/header-form.vue';
import inputForm from "@/components/form/input-form.vue";
import { ref, onMounted, watch, nextTick, onUnmounted } from "vue";
import { CountrySelect, RegionSelect, CountryRegionData } from 'vue3-country-region-select';
import 'flag-icons/css/flag-icons.min.css';
import RevModal from '@/components/mini_components/RevModal.vue';
import btnForm from "@/components/form/btn-form.vue";
import { useRouter } from 'vue-router'; // Import useRouter
const router = useRouter(); // Use useRouter here
const region = ref(null);
const code = ref('');
const country = ref('');
const city = ref('');
const isMounted = ref(false);
const showModal = ref(false);
const regions = ref([]);
const selectElement = ref(null);
const modalType = ref('')

watch(code, (newCountry) => {
    console.log('Selected Country:', newCountry);
});

const handleOpenModal = (type) => {
    modalType.value = type;
    showModal.value = true;
}

const updateRegions = () => {
    if (selectElement.value) {
        regions.value = [];
        selectElement.value.options.forEach((element, index) => {
            if (index !== 0) {
                regions.value.push(element.textContent);
            }
        });
        console.log("regions:::: ", regions.value);
    }
};

const handleSelectedCountry = async (data) => {
    await nextTick();
    code.value = data.code;
    country.value = data.country;
    selectElement.value = document.querySelector('.select-region');
};


const handleSelectedCity = (data) => {
    city.value = data;
};

onMounted(async () => {
    await nextTick();

    isMounted.value = true;
    selectElement.value = document.querySelector('.select-region');
    selectElement.value.classList.add('label-transparent');

    updateRegions();

    const observer = new MutationObserver(() => {
        updateRegions();
    });

    if (selectElement.value) {
        observer.observe(selectElement.value, { childList: true, subtree: false });
    }

    // Cleanup observer when component is unmounted
    onUnmounted(() => {
        observer.disconnect();
    });
});
</script>


<style scoped>
.select-region {
    background-color: blue;
    color: white;
}

.label-transparent {
    display: none;
}
</style>
