<template>
    <div class="w-full flex flex-col gap-[6px]">
        <div class="text-[24px] w-full text-[#18171A] font-bolder">
            <slot name='title'></slot>
        </div>
        <div class="text-[14px] w-full text-[var(--label)] font-[400]">
            <slot name='subtitle'></slot>
        </div>
    </div>
</template>
<!-- <script setup>
import {defineProps} from 'vue'

const $Props = defineProps({
    title : {default:'Header here...'},
    subtitle : {} 
})

</script> -->