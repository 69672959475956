<template>
  <router-view />
</template>

<style lang="scss">

html,body {
  height: 100%;
}

@font-face {
  font-family: sf;
  src: url(./assets/fonts/sf-pro-text-regular.otf);
}

@font-face {
  font-family: sfBold;
  src: url(./assets/fonts/sf-pro-text-bold.otf);
}

:root{
    --font : sf;
    --fontBold : sfBold;
    --black : #18171A;
    --outline:#E4E3E8;
    --label : #616068;
    --gray : #fcfcfc;
    --blue: #3C70FF;
    --gradient: linear-gradient(180deg, rgb(16, 186, 255) 0%, rgb(129, 3, 255) 100%);
}

#app {
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

.font-bolder{
  font-family: var(--fontBold)!important;
}



</style>
