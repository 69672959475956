<template>
    <phoneContainer>
        <MobileNavbar></MobileNavbar>
        <div class="bg-white h-full flex items-center p-[16px] flex-col gap-[16px] overflow-y-[auto]">
            <div class="pt-[24px] w-full ">
                <div class="w-[calc(75%+32px)] h-[4px] bg-[var(--blue)] rounded mx-[-16px]"></div>
            </div>
            <div class="relative w-full ">
                <div class="absolute"><BackNav class="relative !top-[0px] !m-[0px] !p-[0px] !pt-[0px] cursor-pointer" @click="router.push({name:'taxinfo'})"></BackNav></div>
                <div class="w-full text-center font-bolder text-[16px]">Tax Information</div>
            </div>
            <div class="w-full flex flex-col gap-[12px]">
                <div class="text-[24px] font-bolder">What is your Tax Identification Number?</div>
            </div>
            <div class="w-full flex flex-col gap-[12px] grow-[1] max-h-[100%] overflow-y-auto justify-center items-center">
                <input v-if="pageIsReady" v-model="taxVal" type="number" class="text-[20px] outline-none w-full h-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"/>
            </div>
            <div class="w-full pb-[12px] flex flex-col gap-[16px]">
                <div class="text-[var(--blue)] w-full text-center text-[16px]">I don’t have a Tax Identification Number</div>
                <btnForm :disabled="isDisabled" class="small-pulse" @click="onProcess()">
                    <span>Continue</span>
                </btnForm>
            </div>
        </div>
    </phoneContainer>
</template>
<script setup>
import {ref,computed,onMounted,watch} from 'vue'
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import popupModal from '@/components/mini_components/popupModal.vue';
import { useRouter } from 'vue-router';
import btnForm from "@/components/form/btn-form.vue";
const router = useRouter()
const pageIsReady = ref(false)
const popUpModalElem = ref(null)



const isDisabled = ref(true)
const taxVal = ref("")

watch(taxVal,(v)=>{
    console.log(v)
    if(v == ""){
        isDisabled.value = true
    }else{
        isDisabled.value = false
    }
})


const onProcess = ()=>{
    localStorage.setItem('taxinfo_tin', taxVal.value);
    router.push({name:'taxinfo3'})
}


onMounted(()=>{
    let tin = localStorage.getItem('taxinfo_tin');
    if(tin){
        taxVal.value = tin
    }
    pageIsReady.value = true
})
</script>