<template>
    <div
        class="absolute bottom-0 w-full flex flex-col ease-in-out duration-1000 bg-[#171c23] overflow-auto pointer-events-[all] bg-opacity-90 max-h-[90%] h-[auto] z-[999] px-[16px] py-[5px] shadow-lg will-change-transform text-white">
        <div class="flex  flex-row justify-between content-center w-full">
            <div>Apple ID</div>
            <div class="text-[#3C70FF] text-sm cursor-pointer" @click="emit('modal:close')">Cancel</div>
        </div>
        <div class="w-full hidden sm:block relative items-center justify-center" data-v-de3b0d53="">
            <div class="border-t border-black border-opacity-20 border-[var(--outline)] "></div>
        </div>
        <div class="pt-[44px] pb-[24px] flex flex-row justify-center text-[26px] font-bold">
            Sign in with Apple
        </div>

        <div class="px-[16px]">
            <div class="flex flex-row mb-[16px]">
                <div class="p-4">
                    <svg xmlns="http://www.w3.org/2000/svg" class="rotate-[315deg]" fill="white" width="34px"
                        height="34px" viewBox="0 0 8 8">
                        <path
                            d="M5.5 0c-1.38 0-2.5 1.12-2.5 2.5 0 .16 0 .32.03.47l-3.03 3.03v2h3v-2h2v-1l.03-.03c.15.03.31.03.47.03 1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5zm.5 1c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z" />
                    </svg>
                </div>
                <div>
                    <div class="text-lg">Fast and easy</div>
                    <div class="text-md text-[rgb(119,119,121)]">Sign in to apps and websites with the Apple ID
                        you
                        already have.</div>
                </div>
            </div>

            <div class="flex flex-row mb-[16px]">
                <div class="p-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34px" height="34px" viewBox="0 0 512 512"
                        fill="white">
                        <title />
                        <path
                            d="M429.58,209.08h0c-15.06-6.62-32.38,1.31-38.5,17.62L356,312H344.73V80c0-17.6-13.3-32-29.55-32h0c-16.26,0-29.55,14.4-29.55,32V231.75l-14.78.25V32c0-17.6-13.3-32-29.55-32h0c-16.25,0-29.55,14.4-29.55,32V231.75L197,232V64c0-17.6-13.3-32-29.55-32h0c-16.26,0-29.55,14.4-29.55,32V247.75L123.1,248V128c0-17.6-13.3-32-29.55-32h0C77.3,96,64,110.4,64,128V344c0,75.8,37.13,168,169,168,40.8,0,79.42-7,100.66-21a121.41,121.41,0,0,0,33.72-33.31,138,138,0,0,0,16-31.78l62.45-175.14C452,234.46,444.64,215.71,429.58,209.08Z" />
                    </svg>
                </div>
                <div>
                    <div class="text-lg">Respects your privacy</div>
                    <div class="text-md text-[rgb(119,119,121)]">Apps can only ask for name and email. Apple
                        will
                        never track you.</div>
                </div>
            </div>

            <div class="flex flex-row">
                <div class="p-4">

                    <svg xmlns="http://www.w3.org/2000/svg" width="34px" height="34px" viewBox="0 0 14 14" role="img"
                        focusable="false" aria-hidden="true" fill="white">
                        <path
                            d="m 12.668125,7.28312 -2.16,-0.84375 c -0.07687,-0.03 -0.23625,-0.0694 -0.41625,0 l -2.16,0.84375 C 7.73125,7.36187 7.6,7.54562 7.6,7.75 c 0,2.0925 1.288125,3.54 2.491875,4.01062 0.18,0.0694 0.3375,0.03 0.41625,0 C 11.47,11.38562 13,10.08437 13,7.75 13,7.54562 12.86875,7.36187 12.668125,7.28312 Z M 10.3,10.87 l 0,-3.54563 1.790625,0.69938 C 11.985625,9.65687 10.94875,10.5625 10.3,10.87 Z M 5.2,7 C 6.525625,7 7.6,5.92562 7.6,4.6 7.6,3.27437 6.525625,2.2 5.2,2.2 3.874375,2.2 2.8,3.27437 2.8,4.6 2.8,5.92562 3.874375,7 5.2,7 Z M 7,7.75 C 7,7.7031 7.015,7.66 7.020625,7.615 6.97375,7.61312 6.92875,7.6 6.88,7.6 l -0.313125,0 C 6.150625,7.79125 5.6875,7.9 5.2,7.9 4.7125,7.9 4.25125,7.79125 3.833125,7.6 L 3.52,7.6 C 2.12875,7.6 1,8.72875 1,10.12 l 0,0.78 c 0,0.49687 0.403125,0.9 0.9,0.9 l 6.6,0 c 0.1275,0 0.249375,-0.0281 0.36,-0.075 C 7.8475,10.92062 7,9.53687 7,7.75 Z" />
                    </svg>
                </div>
                <div>
                    <div class="text-lg">Hide your email</div>
                    <div class="text-md text-[rgb(119,119,121)]">Keep your email address private but still
                        receive
                        message from the app.</div>
                </div>
            </div>
        </div>
        <div class="h-[auto] w-[auto] self-center">
            <img class="h-[40px]" src="./../../assets/icons/shake_hands.png" />
        </div>

        <div class="self-center text-center w-full text-[12px] px-[16px] text-[rgb(119,119,121)] mb-[34px]">
            <span class="">In setting up Sign in with Apple, information about your Apple ID and
                devices use patterns may
                be used by Apple to help prevent fraud.</span>
            <span class="text-[#3C70FF]"> See how your data is managed...</span>
        </div>

        <div class="self-center pb-[16px]">
            <button type="submit" @click="processtoCode()"
                class="flex gap-[6px] justify-center items-center w-[auto] text-[16px] font-[600] text-white border-2 border-[var(--blue)] bg-[var(--blue)] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Continue</button>
        </div>
    </div>
</template>

<script setup>
import { defineEmits } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()

const processtoCode = () =>
{
    router.push('/term')
}

const emit = defineEmits(['modal:close'])

</script>

