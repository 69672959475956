<template>
  <PhoneContainer>
    <div class="bg-[var(--blue)] w-full absolute h-full">
      <MobileNavbar :text-color="'white'"></MobileNavbar>
      <div class="w-full h-full flex flex-col gap-[16px] justify-center items-center p-[16px]">
          <div class="grow-[1] flex flex-col gap-[12px] justify-center items-center w-full text-white">
            <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.8 1.84752C45.9658 1.17446 46.5487 0.837932 47.1684 0.706212C47.7167 0.589666 48.2833 0.589666 48.8316 0.706212C49.4513 0.837932 50.0342 1.17446 51.2 1.84752L86.3692 22.1525C87.535 22.8255 88.1179 23.1621 88.5418 23.6329C88.9169 24.0495 89.2002 24.5402 89.3734 25.0733C89.5692 25.6759 89.5692 26.3489 89.5692 27.695V68.305C89.5692 69.6511 89.5692 70.3241 89.3734 70.9267C89.2002 71.4598 88.9169 71.9505 88.5418 72.3671C88.1179 72.8379 87.535 73.1745 86.3692 73.8475L51.2 94.1525C50.0342 94.8255 49.4513 95.1621 48.8316 95.2938C48.2833 95.4103 47.7167 95.4103 47.1684 95.2938C46.5487 95.1621 45.9658 94.8255 44.8 94.1525L9.63078 73.8475C8.46501 73.1745 7.88212 72.8379 7.4582 72.3671C7.08312 71.9505 6.79978 71.4598 6.62656 70.9267C6.43078 70.3241 6.43078 69.6511 6.43078 68.305V27.695C6.43078 26.3489 6.43078 25.6759 6.62656 25.0733C6.79978 24.5402 7.08312 24.0495 7.4582 23.6329C7.88212 23.1621 8.46501 22.8255 9.63078 22.1525L44.8 1.84752Z" fill="#1E024F" fill-opacity="0.27"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M64.8839 35.1161C65.372 35.6043 65.372 36.3957 64.8839 36.8839L42.8839 58.8839C42.3957 59.372 41.6043 59.372 41.1161 58.8839L31.1161 48.8839C30.628 48.3957 30.628 47.6043 31.1161 47.1161C31.6043 46.628 32.3957 46.628 32.8839 47.1161L42 56.2322L63.1161 35.1161C63.6043 34.628 64.3957 34.628 64.8839 35.1161Z" fill="white"/>
            </svg>
            <div class="text-[24px] font-bolder">You're in!</div>
            <div class="w-full text-center text-[14px] text-[#FFFFFFCC]">You can now top up your wallet and begin your investment journey!</div>

          </div>
          <div class="w-full pb-[24px]">
              <btnForm class="!bg-white !text-[var(--blue)]" @click="router.push({name:'dashboard2'})">Finish</btnForm>
          </div>
      </div>
    </div>
  </PhoneContainer>
</template>

<script setup>
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import PhoneContainer from "@/components/container/PhoneContainer.vue";
import { useRoute, useRouter } from 'vue-router';
import {ref} from 'vue';
import btnForm from "@/components/form/btn-form.vue";
const route = useRoute();
const router = useRouter();

</script>


<style scoped>
</style>           