<template>
  <PhoneContainer>
    <div class="bg-[var(--blue)] w-full absolute h-full">
      <MobileNavbar :text-color="'white'"></MobileNavbar>
      <p class="term_title mt-[60px] px-[20px]">Welcome to xCube</p>
      <p class="term_subtitle px-[20px] mt-[8px]">You currently have limited access. Verify your identity and complete KYC within 7 days to access all the exciting features we have to offer.</p>
    </div>
    <div class="absolute z-[2]  p-[16px] w-full bottom-[0] grid gap-[5px]">
        <div class="grid gap-[10px]">
          <div class="flex gap-1 small-pulse items-center" @click="termAgreed = ! termAgreed">
            <input id="link-checkbox" type="checkbox" :checked="termAgreed" class="custom-checkbox"></input>
            <p class="methods_terms">I have read and agree to the <span class="underline">Terms & Conditions</span></p>
         
        </div>
            <btnForm :disabled="!termAgreed" @click="goToDashboard()" class="small-pulse" theme="3">Get ready explore!</btnForm>
        </div>
    </div>
  </PhoneContainer>
</template>

<script setup>
import btnForm from "@/components/form/btn-form.vue";
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import PhoneContainer from "@/components/container/PhoneContainer.vue";
import { useRoute, useRouter } from 'vue-router';
import {ref} from 'vue';
const route = useRoute();
const router = useRouter();
const termAgreed = ref(false)

const goToDashboard = () =>{
  router.push({name:'dashboard'})
}
</script>


<style scoped>
.term_smalltext{
    /* text */


font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

/* Text/Body/$text-06 */
color: #FFFFFF;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 1;

}
.term_title{
/* title */



font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

/* Text/Body/$text-06 */
color: #FFFFFF;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

}

.term_subtitle{


font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */
letter-spacing: -0.01em;

/* Text/Body/$text-05 */
color: rgba(255, 255, 255, 0.8);


/* Inside auto layout */
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

}
</style>