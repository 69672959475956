<template>
    <div
        class="absolute rounded-t-lg bottom-0 w-full flex flex-col ease-in-out duration-1000 h-[auto] max-h-[95%] overflow-auto pointer-events-[all] z-[999] py-[5px] shadow-lg will-change-transform ">
        <div class="relative bg-[rgb(87,87,87)] rounded-t-lg h-[100%] text-white pt-3">
            <div class="flex h-[24px] flex-row justify-between content-center w-full px-3">
                <div class="text-[#3C70FF]" @click="emit('modal:close')">
                    Cancel
                </div>
                <div class="inline-flex items-center">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px"
                            height="18px" viewBox="0 0 512 512" version="1.1">
                            <title>lock-filled</title>
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="add" fill="white" transform="translate(85.333333, 42.666667)">
                                    <path
                                        d="M170.666667,3.55271368e-14 C241.156267,3.55271368e-14 298.666667,57.6949333 298.666667,128 L298.666667,213.333333 L341.333333,213.333333 L341.333333,426.666667 L1.42108547e-14,426.666667 L1.42108547e-14,213.333333 L42.6666667,213.333333 L42.6666667,128 C42.6666667,57.6949333 100.176853,3.55271368e-14 170.666667,3.55271368e-14 Z M170.666667,42.6666667 C123.33568,42.6666667 85.3333333,81.6625067 85.3333333,128 L85.3333333,128 L85.3333333,213.333333 L256,213.333333 L256,128 C256,81.6625067 217.9968,42.6666667 170.666667,42.6666667 Z"
                                        id="Combined-Shape">

                                    </path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    &nbsp;
                    accounts.google.com
                </div>
                <div class="relative flex gap-[16px] justify-center">
                    <div class="flex flex-col items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px"
                            height="18px" viewBox="0 0 24 24" version="1.1">

                            <title>ic_fluent_text_font_size_24_regular</title>
                            <desc>Created with Sketch.</desc>
                            <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="ic_fluent_text_font_size_24_regular" class="fill-[#3C70FF]" fill-rule="nonzero">
                                    <path
                                        d="M10.2117996,17.11 L15.0431923,3.49912344 C15.2655277,2.87273643 16.1123594,2.83593533 16.4081048,3.38865641 L16.456812,3.49920006 L21.9568006,18.999686 C22.0953136,19.3900539 21.8911449,19.8187965 21.5007771,19.9573094 C21.14294,20.0842797 20.7528573,19.9233021 20.5836383,19.5949082 L20.5431537,19.5012859 L18.9457996,15 L12.5517996,15 L10.9724231,19.4521469 C10.9199434,19.6396056 10.7952983,19.804822 10.617297,19.9044945 L10.5251332,19.9481977 C10.171909,20.087487 9.7764843,19.9401182 9.59599148,19.6177829 L9.55228824,19.5256192 L8.5557996,17 L4.4427996,17 L3.44747776,19.5208817 C3.30788849,19.8739875 2.9301318,20.0620782 2.57143476,19.9736808 L2.47427411,19.9426336 C2.1211683,19.8030443 1.93307758,19.4252876 2.02147501,19.0665906 L2.05252224,18.9694299 L5.80613337,9.47427411 C6.0415216,8.87883471 6.84863764,8.84414583 7.1508398,9.36975012 L7.20132289,9.47486675 L10.2117996,17.11 L15.0431923,3.49912344 L10.2117996,17.11 Z M6.50274363,11.792264 L5.0357996,15.5 L7.9637996,15.5 L6.50274363,11.792264 Z M15.7498671,5.99248097 L13.0837996,13.5 L18.4137996,13.5 L15.7498671,5.99248097 Z"
                                        id="🎨-Color">

                                    </path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24"
                            fill="none">
                            <path
                                d="M21 3V8M21 8H16M21 8L18 5.29168C16.4077 3.86656 14.3051 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.2832 21 19.8675 18.008 20.777 14"
                                stroke="#3C70FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>

            </div>
            <div class="bg-white h-[85%] mt-3">
                <div class="text-black inline-flex p-[16px] w-full border-b-2">
                    <img class="h-[24px]" src="./../../assets/icons/google.png" />
                    &nbsp;
                    Sign in with Google
                </div>

                <div class="text-black p-[16px] w-full">
                    <img class="h-[44px]" src="./../../assets/icons/xcubelogo.png" alt="xcube logo">
                    xCube
                </div>

                <div class="text-black p-[16px] w-full mb-[34px]">
                    <div class="mb-4">
                        <span class="text-[25px]">Choose an account</span>
                    </div>

                    <p>to continue to <span class="text-[#3C70FF]">xCube</span></p>
                </div>

                <div class="w-full relative text-black  px-[16px] cursor-pointer" @click="proceedtoterms()" >
                    <div class="flex gap-2 pb-2 border-b-2 border-gray-200">
                        <div class="flex flex-col justify-center">
                            <div class="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full ">
                                <svg class="absolute w-10 h-10 text-gray-400 -left-1" fill="currentColor"
                                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </div>
                        </div>

                        <div>
                            <div>
                                Hamad Salem Naser
                            </div>
                            <p class="text-[14px] font-thin text-[#7C7A82]">hamad@gmail.com</p>
                        </div>
                        <span class="absolute top-0 right-[16px] font-thin text-[#7C7A82] text-[14px]">Signed out</span>
                    </div>

                </div>
                <div class="w-full relative text-black  px-[16px] py-[16px]">
                    <div class="flex gap-2 pb-2 border-b-2 border-gray-200">
                        <div class="flex flex-col justify-center w-10 h-10 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                                class="stroke-[#7C7A82]" fill="none">
                                <path
                                    d="M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                        <div class="flex flex-col justify-center">
                            <span>Use another account</span>
                        </div>

                    </div>
                </div>

                <div class="text-[#7C7A82] p-[16px] w-full mb-[25px] font-thin">
                    <p class="text-[14px]">
                        Before using this app, you can review Groww's <span class="text-[#3C70FF]">privacy policy</span>
                        and <span class="text-[#3C70FF]">terms of
                            service.</span>
                    </p>
                </div>

                <div class="text-[#7C7A82] text-[14px] p-[16px] w-full">
                    <div class="inline-flex items-center">
                        <span class="mr-6">
                            English (United States)
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                            fill="#7C7A82">
                            <path
                                d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z" />
                        </svg>
                    </div>
                </div>

                <div class="text-[#7C7A82] text-[14px] p-[16px] w-full ">
                    <span class="mr-6">Help</span>
                    <span class="mr-6">Privacy</span>
                    <span class="mr-6">Terms</span>
                </div>
            </div>

            <div class="h-[calc(100%-85%-24px)] px-[16px] flex justify-between items-center">
                <div class="flex gap-4">
                    <div @click="emit('modal:close')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="44px" height="44px" viewBox="0 0 24 24"
                            fill="none">
                            <path d="M15 7L10 12L15 17" stroke="#3C70FF" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="flip" width="44px" height="44px"
                            viewBox="0 0 24 24" fill="none">
                            <path d="M15 7L10 12L15 17" stroke="rgb(107 107 107)" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#3C70FF" width="44px" height="44px"
                        viewBox="0 0 24 24">

                        <g id="Save_Up_2" data-name="Save Up 2">
                            <g>
                                <path
                                    d="M18.437,20.937H5.563a2.372,2.372,0,0,1-2.5-2.211v-11a2.372,2.372,0,0,1,2.5-2.212h.462a.5.5,0,0,1,0,1H5.563a1.381,1.381,0,0,0-1.5,1.212v11a1.38,1.38,0,0,0,1.5,1.211H18.437a1.38,1.38,0,0,0,1.5-1.211v-11a1.381,1.381,0,0,0-1.5-1.212h-.462a.5.5,0,0,1,0-1h.462a2.372,2.372,0,0,1,2.5,2.212v11A2.372,2.372,0,0,1,18.437,20.937Z" />
                                <path
                                    d="M8.645,6.213l3-3a.5.5,0,0,1,.35-.15.508.508,0,0,1,.36.15l3,3a.5.5,0,0,1-.71.71l-2.14-2.14v8.47a.508.508,0,0,1-.5.5.5.5,0,0,1-.5-.5V4.763l-2.15,2.16a.5.5,0,0,1-.71-.71Z" />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>

        </div>

    </div>

    <!-- <div class="flex  flex-row justify-between content-center w-full">
        <div>Apple ID</div>
        <div class="text-[#3C70FF] text-sm cursor-pointer" @click="emit('modal:close')">Cancel</div>
    </div> -->
</template>

<script setup>
import { defineEmits } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const proceedtoterms = ()=>{
    router.push({name: 'term'})
}
const emit = defineEmits(['modal:close'])

</script>

<style scoped>
.flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
</style>
