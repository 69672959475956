<template>
    <PhoneContainer>
        <MobileNavbar></MobileNavbar>

        <div class="flex flex-col items-center h-full">
            <div class="flex items-center w-full mt-5 p-[16px]">
                <div class="text-blue-400">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2" class="mr-2">
                        <path d="M5 12H19M5 12L11 6M5 12L11 18" />
                    </svg>
                </div>
                <!-- Paragraph centered between BackNav and the right side -->
                <div class="flex-1 text-center ml-[-50px] font-bolder">
                    <p>Personal details</p>
                </div>

            </div>

            <div class="relative flex flex-col gap-[16px] grow-[1]  px-[16px]">
                <div class="text-left text-[24px] font-bolder">
                    Do you have a holding of over 5% in any listed companies?
                </div>

                <div class="flex items-center justify-between bg-[#F5F5F7] text-lg p-[16px] rounded-[8px]"
                    @click="popUpModalElem._fn.show()">

                    <span class="text-[16px]">Yes</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967Z"
                            fill="#3C70FF" />
                    </svg>
                </div>
                <div class="flex items-center justify-between bg-[#F5F5F7] text-lg p-[16px] rounded-[8px]"
                    @click="popUpModalElem._fn.show()">

                    <span class="text-[16px]">No</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967Z"
                            fill="#3C70FF" />
                    </svg>
                </div>
            </div>
        </div>

        <popupModal v-if="pageIsReady" ref="popUpModalElem">
            <template v-slot:title>
                <label class="text-[20px]">
                    Provide details of your holdings
                </label>
            </template>
            <template v-slot:body>
                <div class=" flex flex-col gap-[16px]">
                    <inputForm :label="'Company Name'"></inputForm>

                    <inputForm :label="'Percentage ownership'"></inputForm>

                    <label class="text-[14px] text-[#3C70FF]  text-center">Add another company</label>

                    <btnForm class="small-pulse" @click="router.push({name:'investmentexperience'})">Continue</btnForm>


                </div>

            </template>
        </popupModal>

    </PhoneContainer>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from "vue";
import popupModal from '@/components/mini_components/popupModal.vue';

import jeboy_slidingModal from "@/components/mini_components/jeboy_slidingModal.vue";
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import PhoneContainer from "@/components/container/PhoneContainer.vue";
import inputForm from "@/components/form/input-form.vue";
import btnForm from "@/components/form/btn-form.vue";
import { useRoute, useRouter } from 'vue-router';
const router = useRouter();

const pageIsReady = ref(false)
const popUpModalElem = ref(null)



onMounted(() => {
    pageIsReady.value = true
})
</script>

<style scoped>
.label-transparent {
    display: none;
}
</style>
