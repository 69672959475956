<template>
    <div
        class="absolute bottom-0 w-full flex flex-col ease-in-out rounded-t-xl duration-1000 bg-white overflow-auto pointer-events-[all] h-[90%] z-[999] px-[16px] py-[5px] shadow-lg will-change-transform text-black">
        <div class="flex flex-row justify-between py-2">
            <span class="text-[24px] font-bold">
                <slot name="header"></slot>
            </span>
            <div class="flex justify-center items-center" @click="emit('modal:close')">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                        fill="#3C70FF" />
                </svg>
            </div>
        </div>

        <div class="w-full mt-[12px]">
            <form class="max-w-md mx-auto">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-[#3C70FF] " aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" id="default-search" v-model="city"
                        class="block w-full p-2 ps-9 text-md font-[100] text-gray-900  rounded-lg bg-[#F5F5F7] focus:outline-none caret-[#3C70FF]  focus:border-blue-500 "
                        placeholder="Search by name" />

                </div>
            </form>
        </div>

        <div class="py-[12px]">
            <dl>
                <template v-for="region in newCityCollection">
                    <div @click="handleSelectCity(region)">
                        <dd class="py-[12px] text-[18px] hover:bg-gray-100 cursor-pointer">
                            {{ region }}
                        </dd>
                    </div>

                </template>
            </dl>
        </div>
    </div>

</template>

<script setup>
import { defineEmits, defineProps, ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

const newCityCollection = ref([]);
const city = ref('');

watch(() => city.value, (searchTerm) => {
    newCityCollection.value = props.forRegions.filter((entry) =>
        entry.toLowerCase().includes(searchTerm.toLowerCase())
    );
});

const props = defineProps({
    forRegions: {
        default: null
    }
})

onMounted(() => {
    newCityCollection.value = props.forRegions;
})

const router = useRouter()

const processtoCode = () => {
    router.push('/term')
}

const handleSelectCity = (city) => {
    emit('select:city', city);
}

const emit = defineEmits(['modal:close'])



</script>
