<template>
  <PhoneContainer>
    <div class="bg-[var(--blue)] w-full absolute h-full">
      <MobileNavbar :text-color="'white'"></MobileNavbar>

      <div class="term_title mt-[60px] px-[20px]">
        <div class="text-white">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" stroke-width="2" class="mr-2">
            <path d="M5 12H19M5 12L11 6M5 12L11 18" />
          </svg>
        </div>
      </div>

      <div class="grow-2 p-5 m-4 bg-white rounded-md h-[calc(90%-100px)] text-[24px] font-bold text-center">Scan
        passport
        <p class="text-[14px] font-normal">Fit your passport inside the frame</p>
      </div>

    </div>

    <div class="absolute z-[2] p-[16px] w-full bottom-[0] grid gap-[5px]">
      <div class="grid gap-[10px]">

        <btnForm @click="router.push({name:'ScanPassport'})" class=" small-pulse font-bold" theme="3">Start Scan
        </btnForm>

      </div>
    </div>
  </PhoneContainer>
</template>


<script setup>
import btnForm from "@/components/form/btn-form.vue";
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import PhoneContainer from "@/components/container/PhoneContainer.vue";
import { useRoute, useRouter } from 'vue-router';
import { ref } from 'vue';
const route = useRoute();
const router = useRouter();


</script>


<style scoped>
.term_smalltext {
  /* text */


  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Text/Body/$text-06 */
  color: #FFFFFF;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;

}

.term_title {
  /* title */



  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Text/Body/$text-06 */
  color: #FFFFFF;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

}

.term_subtitle {


  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  letter-spacing: -0.01em;

  /* Text/Body/$text-05 */
  color: rgba(0, 0, 0, 0.8);


  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

}
</style>