<template>
    <teleport to="#phoneFrame">
        
        <div class="w-full h-full absolute top-[0px] left-[0px] z-[999]" v-show="options.show">
            <div class="w-full h-full absolute top-[0px] left-[0px] bg-[rgba(0,0,0,.5)]" @click="_fn.hide()"></div>
            <Transition name ="popupModalAnimation">
            <div v-if="options.modal" class="main_modal bg-white absolute w-full bottom-0 rounded-[15px_15px_0px_0px] p-[16px] max-h-[90%] overflow-y-hidden flex flex-col">
                <div class="w-full flex">
                    <div class="grow-[1] text-[20px] font-bolder"><slot name="title"></slot></div>
                    <div class="cursor-pointer" @click="_fn.hide()"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.5303 6.53033C18.8232 6.23744 18.8232 5.76256 18.5303 5.46967C18.2374 5.17678 17.7626 5.17678 17.4697 5.46967L12 10.9393L6.53033 5.46967C6.23744 5.17678 5.76256 5.17678 5.46967 5.46967C5.17678 5.76256 5.17678 6.23744 5.46967 6.53033L10.9393 12L5.46967 17.4697C5.17678 17.7626 5.17678 18.2374 5.46967 18.5303C5.76256 18.8232 6.23744 18.8232 6.53033 18.5303L12 13.0607L17.4697 18.5303C17.7626 18.8232 18.2374 18.8232 18.5303 18.5303C18.8232 18.2374 18.8232 17.7626 18.5303 17.4697L13.0607 12L18.5303 6.53033Z" fill="#3C70FF"/>
</svg>
</div>
                </div>
                <div class="w-full grow-[1] max-h-[100%] h-full overflow-y-auto"><slot name="body"></slot></div>
                <div class="w-full"><slot name="footer"></slot></div>
            </div>
            
            </Transition>
        </div>
        
    </teleport>
</template>
<script setup>
    import {ref,defineExpose} from 'vue'
    const options = ref({
        show : false,
        modal : false
    })

    const _fn = {
        show : ()=>{
            options.value.show = true
            setTimeout(()=>{
                options.value.modal = true
            },100)
        },
        hide : ()=>{
            options.value.modal = false
            setTimeout(()=>{
                options.value.show = false
            },500)

            
        }
    }
    defineExpose({_fn:_fn})
</script>
<style scoped>
.popupModalAnimation-enter-active {
  
  animation: popupModalAnimation-in 0.5s;
}
.popupModalAnimation-leave-active {
  animation: popupModalAnimation-in 0.5s reverse;
}
@keyframes popupModalAnimation-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

</style>