<template>
    <div
        class="absolute bottom-0 w-full flex flex-col ease-in-out rounded-t-xl duration-1000 bg-white overflow-auto pointer-events-[all] h-[90%] z-[999] px-[16px] py-[5px] shadow-lg will-change-transform text-black">
        <div class="flex flex-row justify-between py-2">
            <span class="text-[24px] font-bold">
                <slot name="header"></slot>
            </span>
            <div class="flex justify-center items-center" @click="emit('modal:close')">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                        fill="#3C70FF" />
                </svg>
            </div>
        </div>

        <div class="w-full mt-[12px]">
            <form class="max-w-md mx-auto">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-[#3C70FF] " aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" v-model="address" id="default-search"
                        class="block w-full p-2 ps-9 text-md font-[100] text-gray-900  rounded-lg bg-[#F5F5F7] focus:outline-none caret-[#3C70FF]  focus:border-blue-500 "
                        placeholder="Search by name" />

                </div>
            </form>
        </div>

        <div class="py-[125px] w-full text-center text-gray-500" v-if="emptySearch == true">
            Please provide your employer's address
        </div>

        <div class="py-[12px] w-full">
            <dl>
                <template v-for="address in newAddressCollection">
                    <div @click="handleSelectCompanyAddress(address)">
                        <dd class="py-[12px] text-[18px] hover:bg-gray-100 cursor-pointer">
                            {{ address.name }}
                            <p class="text-sm text-gray-500">
                                {{ address.location }}
                            </p>
                        </dd>
                    </div>

                </template>
            </dl>
            <div @click="handleNext" class="py-[12px] relative w-full text-[18px] hover:bg-gray-100 cursor-pointer"
                v-if="newAddressCollection.length > 0">
                <p>I don’t see the address in this list</p>
                <svg class="absolute top-[50%] translate-y-[-50%] right-0" xmlns="http://www.w3.org/2000/svg"
                    width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                    <path d="M10 7L15 12L10 17" stroke="#3C70FF" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>

        </div>
    </div>

</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
    forRegions: {
        default: null
    }
})

const newAddressCollection = ref([])

const emptySearch = ref(true);

const address = ref('');

watch(() => address.value, (searchTerm) => {
    if (!searchTerm) {
        emptySearch.value = true;
        newAddressCollection.value = [];
        return;
    }
    newAddressCollection.value = addresses.filter((entry) =>
        entry.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    emptySearch.value = newAddressCollection.value.length === 0;
});

const handleNext = () => {
    emit('next:page');
}


const router = useRouter()

const processtoCode = () => {
    router.push('/term')
}

const handleSelectCompanyAddress = (data) => {
    emit('select:companyAddress', data);
}

const emit = defineEmits(['modal:close', 'select:companyAddress', "next:page"])

const addresses = [
    { name: 'Terminal 5', location: 'Hounslow, Great Britain' },
    { name: 'Terma Bania', location: 'Srodkowa, Poland' },
    { name: 'Termoli', location: 'Italy' },
    { name: 'The Shard', location: 'London, Great Britain' },
    { name: 'Eiffel Tower', location: 'Paris, France' },
    { name: 'Grand Central Terminal', location: 'New York City, USA' },
    { name: 'Sydney Opera House', location: 'Sydney, Australia' },
    { name: 'Colosseum', location: 'Rome, Italy' },
    { name: 'Chhatrapati Shivaji Terminus', location: 'Mumbai, India' },
    { name: 'Burj Khalifa', location: 'Dubai, UAE' },
    { name: 'Statue of Liberty', location: 'New York City, USA' },
    { name: 'Forbidden City', location: 'Beijing, China' },
    { name: 'Big Ben', location: 'London, Great Britain' },
    { name: 'Taj Mahal', location: 'Agra, India' },
    { name: 'Louvre Museum', location: 'Paris, France' },
    { name: 'Kremlin', location: 'Moscow, Russia' },
    { name: 'Christ the Redeemer', location: 'Rio de Janeiro, Brazil' },
    { name: 'Tokyo Tower', location: 'Tokyo, Japan' },
    { name: 'CN Tower', location: 'Toronto, Canada' },
    { name: 'Guggenheim Museum', location: 'Bilbao, Spain' },
    { name: 'Petronas Towers', location: 'Kuala Lumpur, Malaysia' },
    { name: 'Mount Fuji', location: 'Honshu, Japan' },
    { name: 'Angkor Wat', location: 'Siem Reap, Cambodia' },
    { name: 'Table Mountain', location: 'Cape Town, South Africa' },
    { name: 'Brandenburg Gate', location: 'Berlin, Germany' },
    { name: 'Times Square', location: 'New York City, USA' },
    { name: 'Golden Gate Bridge', location: 'San Francisco, USA' },
    { name: 'Niagara Falls', location: 'Ontario, Canada' },
    { name: 'Acropolis', location: 'Athens, Greece' },
    { name: 'Buckingham Palace', location: 'London, Great Britain' },
    { name: 'Machu Picchu', location: 'Cusco Region, Peru' },
    { name: 'St. Peter’s Basilica', location: 'Vatican City' },
    { name: 'Victoria Harbour', location: 'Hong Kong, China' },
    { name: 'Empire State Building', location: 'New York City, USA' },
    { name: 'Dubai Mall', location: 'Dubai, UAE' },
    { name: 'Hollywood Walk of Fame', location: 'Los Angeles, USA' },
    { name: 'Giza Pyramids', location: 'Giza, Egypt' },
    { name: 'Berlin Wall Memorial', location: 'Berlin, Germany' },
    { name: 'Walt Disney World', location: 'Orlando, USA' },
    { name: 'Pompeii', location: 'Naples, Italy' },
    { name: 'Alhambra', location: 'Granada, Spain' },
    { name: 'Chichen Itza', location: 'Yucatán, Mexico' },
    { name: 'Stonehenge', location: 'Wiltshire, Great Britain' },
    { name: 'Santorini', location: 'Cyclades, Greece' },
    { name: 'Banff National Park', location: 'Alberta, Canada' },
    { name: 'Versailles Palace', location: 'Versailles, France' },
    { name: 'Grand Canyon', location: 'Arizona, USA' },
    { name: 'Sagrada Familia', location: 'Barcelona, Spain' },
    { name: 'Fushimi Inari Shrine', location: 'Kyoto, Japan' }
];





</script>
