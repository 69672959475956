<template>
        <phoneContainer >
            <MobileNavbar></MobileNavbar>
            <div class="flex w-full h-full flex-col relative p-[24px_16px]">
                <div class="flex w-full flex-col grow-[1] justify-center items-center">
                    <img class="w-[117px] h-[117px]" src="./../assets/icons/confirmid.png"/>

                    <div class="flex flex-col w-full gap-[12px]">
                        <div class="text-[24px] text-center font-bolder">Confirm your identification</div>
                        <div class="text-[14px] text-center">To complete your account verification, please take a selfie with your ID (Passport or Emirates ID). 
                            The photo should clearly show your face and the entire ID.</div>
                    </div>

                </div>
                <div class="flex flex-col gap-[12px] w-full">
                        <div class="bg-[#F5F5F7] p-[16px] rounded-md flex gap-[12px] justify-start items-start">
                            <svg class="mt-[-10px]" width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.916992 9.99996C0.916992 5.00241 5.00278 0.916626 10.0003 0.916626C14.9979 0.916626 19.0837 5.00241 19.0837 9.99996C19.0837 14.9975 14.9979 19.0833 10.0003 19.0833C5.00278 19.0833 0.916992 14.9975 0.916992 9.99996ZM10.0003 2.41663C5.83121 2.41663 2.41699 5.83084 2.41699 9.99996C2.41699 14.1691 5.83121 17.5833 10.0003 17.5833C14.1694 17.5833 17.5837 14.1691 17.5837 9.99996C17.5837 5.83084 14.1694 2.41663 10.0003 2.41663ZM10.0003 8.41663C10.4145 8.41663 10.7503 8.75241 10.7503 9.16663V13.3333C10.7503 13.7475 10.4145 14.0833 10.0003 14.0833C9.58611 14.0833 9.25033 13.7475 9.25033 13.3333V9.16663C9.25033 8.75241 9.58611 8.41663 10.0003 8.41663ZM9.99575 5.91663C9.58153 5.91663 9.24575 6.25241 9.24575 6.66663C9.24575 7.08084 9.58153 7.41663 9.99575 7.41663H10.0032C10.4174 7.41663 10.7532 7.08084 10.7532 6.66663C10.7532 6.25241 10.4174 5.91663 10.0032 5.91663H9.99575Z" fill="#3C70FF"/>
                            </svg>

                            <div class="text-[11px] grow-[1] text-[#7C7A82]">By uploading this photo, you agree to let us use it for identity verification purposes and confirm that you are the rightful owner of the ID.</div>
                        </div>
                        <btnForm class="small-pulse" @click="router.push({name:'Verify_Indentity2'})">Take a selfie</btnForm>
                </div>
            </div>

        </phoneContainer>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import phoneContainer from '@/components/container/PhoneContainer.vue';
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import { useRouter } from 'vue-router';
import btnForm from "@/components/form/btn-form.vue";
import Modal from "./../components/mini_components/ModalContent.vue"
const router = useRouter()


// const occupations = [
//     {
//         name: 'Aerospace',
//         titles: [
//             "Mechanical Engineer", "Aircraft/Spacecraft Designer", "Data Processing Manager", "Military Aerospace Engineer", "Inspector and Compliance Officer", "Drafter", "Aerospace Technician"
//         ]
//     },

//     {
//         name: 'Transport',
//         titles: [
//             "Logistics Coordinator", "Transport Manager", "Delivery Driver", "Freight Forwarder", "Transportation Planner","Supply Chain Analyst","Fleet Manager","Warehouse and Distribution Supervisor","Railway Operator","Airport Ground Staff"
//         ]
//     },

//     {
//         name: 'Computer',
//         titles: [
//             "Software Engineer","Data Scientist","Cybersecurity Analyst","Web Developer","Cloud Solutions Architect","AI/ML Engineer","Network Administrator","IT Support Specialist","Database Administrator","Game Developer"
//         ]
//     },

//     {
//         name: 'Telecommunication',
//         titles: [
//             "Telecommunications Engineer","Network Architect","Telecommunications Technician","Network Administrator","VoIP Engineer (Voice over Internet Protocol Engineer)","RF Engineer (Radio Frequency Engineer)","Telecommunications Analyst","Fiber Optic Technician","Sales Account Manager (Telecom)","Telecommunications Project Manager"
//         ]
//     },

//     {
//         name: 'Agriculture',
//         titles: [
//             "Agricultural Engineer","Farm Manager","Agronomist","Horticulturist","Soil and Water Conservationist","Crop Consultant","Agricultural Technician","Irrigation Specialist","Agricultural Economist","Livestock Manager"
//         ]
//     },

//     {
//         name: 'Construction',
//         titles: [
//             "Construction Manager","Site Supervisor","Civil Engineer","Construction Estimator","Project Manager (Construction)","Architectural Drafter","Quantity Surveyor","Health and Safety Officer (Construction)","Mason/Bricklayer","Structural Engineer",
//         ]
//     },

//     {
//         name: 'Education',
//         titles: [
//             "Teacher","School Principal","Academic Coordinator","Education Consultant","Curriculum Developer","Special Education Teacher","Guidance Counselor","Instructional Designer","Education Administrator","Librarian",
//         ]
//     }
// ]


</script>