<template lang="">
    <PhoneContainer>
      <MobileNavbar />
      <BackNav3 :routeTo="'employmentstatus'"></BackNav3>
      <div class="mt-[34px] w-full ">
            <div class="w-[calc(10%)] h-[4px] bg-[var(--blue)] rounded mx-[-16px]"></div>
        </div>
      
      <div class="flex flex-col px-[16px]">
    
        <div class="w-full">
            <h2 class="text-[24px] font-bold pt-[44px]">Please provide employment details</h2>
        </div>

        <div class="w-full pt-[12px]">
            <div class="mb-[24px] relative">
                <inputForm :label="'Employer name'" ></inputForm>
            </div>

            <region-select class="w-full select-region" v-model="region" :country="code" :region="region" />

            <div class="mb-[24px] relative">
                <inputForm :label="'Company Address'" @click="handleOpenModal('address')" :inputModel="companyAddress"></inputForm>
            </div>

            <div class="mb-[16px] mt-[20px] relative">
                <inputForm :label="'Industry'" @click="handleOpenModal('industry')" :inputModel="industry">
                </inputForm>
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 1024 1024"
                    class="icon absolute top-[60%] right-[12px]" version="1.1">
                    <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                        fill="#3C70FF" />
                </svg>
            </div>

            <div class="mb-[24px] relative">
                <inputForm :label="'Job title'" @click="handleOpenModal('titles')" :inputModel="selectedTitle"></inputForm>
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 1024 1024"
                    class="icon absolute top-[60%] right-[12px]" version="1.1">
                    <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                        fill="#3C70FF" />
                </svg>
            </div>

            <div class="mb-[16px]">
                <region-select class="w-full select-region label-transparent" v-model="region" :country="code" :region="region" />
            </div>
        
            <div class="mb-[24px] relative">
                <inputForm :label="'Website (optional)'" ></inputForm>
            </div>


        </div>
        
      </div>
      <div class="w-full absolute bottom-0 p-[24px]">
            <router-link :to="{path: 'sourceofIncome'}" v-slot="{href, navigate}">
            <button :href="href" 
                class="w-full bg-[#3C70FF] text-white py-3 rounded-lg font-medium hover:bg-[#3361E6] transition" @click="navigate">
                Continue
                </button>
            </router-link>
        </div>

      <PersonalDetailsModal v-if="isMounted" :forRegions="regions" :show="showModal" :occupations="newIndustryCollection" :titles="newTitlesCollection" :type="modalType" @modal:close="showModal = false" @sected:address="handleAddress" @country:code="handleSelectedCountry" @select:industry="handleSelectedIndustry" @select:title="handleSelectedTitle"></PersonalDetailsModal>

      <!-- <RevModal v-if="isMounted" :forRegions="regions" :type="modalType" :show="showModal"
            :header="'Select country'" @modal:close="showModal = false" @select:country="handleSelectedCountry"
            @select:city="handleSelectedCity" :cityHeader="'Select city'">
        </RevModal> -->

    </PhoneContainer>
  </template>

<script setup>
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import PhoneContainer from "@/components/container/PhoneContainer.vue";
import btnForm from "@/components/form/btn-form.vue";
import BackNav3 from "@/components/mini_components/BackNav3.vue";
import mapbutton from '@/assets/mapbutton.png';
import map5 from '@/assets/map5.png';
import map6 from '@/assets/map6.png';
import headerForm from '@/components/form/header-form.vue';
import inputForm from "@/components/form/input-form.vue";
import { ref, onMounted, watch, nextTick, onUnmounted } from "vue";
import 'flag-icons/css/flag-icons.min.css';
import RevModal from '@/components/mini_components/RevModal.vue';
import { useRoute, useRouter } from 'vue-router';
import PersonalDetailsModal from "@/components/mini_components/PersonalDetailsModal.vue";
import { CountrySelect, RegionSelect, CountryRegionData } from 'vue3-country-region-select';

const route = useRoute();

const img_src = ref(null);
const defaultDisplay = ref(true);

const region = ref(null);
const code = ref('');
const country = ref('');
const city = ref('');
const isMounted = ref(false);
const showModal = ref(false);
const regions = ref([]);
const selectElement = ref(null);
const modalType = ref('');
const companyAddress = ref('');
const newIndustryCollection = ref(null);

const router = useRouter()
const industry = ref('');
const selectedTitle = ref('');
const titles = ref([]);
const newTitlesCollection = ref([]);

watch(code, (newCountry) => {
    console.log('Selected Country:', newCountry);
});

const handleOpenModal = (data) => {
    modalType.value = data;
    showModal.value = true;

    if (data == 'industry') {
        newIndustryCollection.value = occupations;
        console.log("cj bayot", newIndustryCollection.value)
    }
    if (data == 'titles') {
        newTitlesCollection.value = titles.value;
    }
}

const updateRegions = () => {
    if (selectElement.value) {
        regions.value = [];
        selectElement.value.options.forEach((element, index) => {
            if (index !== 0) {
                regions.value.push(element.textContent);
            }
        });
        console.log("regions:::: ", regions.value);
    }
};

const handleSelectedCountry = async (data) => {
    await nextTick();
    code.value = data;
    selectElement.value = document.querySelector('.select-region');
};


const handleSelectedCity = (data) => {
    city.value = data;
};

const handleAddress = (address) => {
    companyAddress.value = address;
}

const handleSelectedIndustry = (data) => {
    // console.log('mao nani doms', data)
    industry.value = data.industry;
    titles.value = data.title;
}

const handleSelectedTitle = (data) => {
    selectedTitle.value = data
}

onMounted(async () => {

    await nextTick();

    isMounted.value = true;
    selectElement.value = document.querySelector('.select-region');
    selectElement.value.classList.add('label-transparent');

    updateRegions();

    const observer = new MutationObserver(() => {
        updateRegions();
    });

    if (selectElement.value) {
        observer.observe(selectElement.value, { childList: true, subtree: false });
    }

    onUnmounted(() => {
        observer.disconnect();
    });

    img_src.value = mapbutton;


    if (route.query.location == 2) {
        img_src.value = map5;
        defaultDisplay.value = false;
    } else if (route.query.location == 3) {
        img_src.value = map6;
        defaultDisplay.value = false;
    }
});

const occupations = [
    {
        name: 'Aerospace',
        titles: [
            "Mechanical Engineer", "Aircraft/Spacecraft Designer", "Data Processing Manager", "Military Aerospace Engineer", "Inspector and Compliance Officer", "Drafter", "Aerospace Technician"
        ]
    },

    {
        name: 'Transport',
        titles: [
            "Logistics Coordinator", "Transport Manager", "Delivery Driver", "Freight Forwarder", "Transportation Planner", "Supply Chain Analyst", "Fleet Manager", "Warehouse and Distribution Supervisor", "Railway Operator", "Airport Ground Staff"
        ]
    },

    {
        name: 'Computer',
        titles: [
            "Software Engineer", "Data Scientist", "Cybersecurity Analyst", "Web Developer", "Cloud Solutions Architect", "AI/ML Engineer", "Network Administrator", "IT Support Specialist", "Database Administrator", "Game Developer"
        ]
    },

    {
        name: 'Telecommunication',
        titles: [
            "Telecommunications Engineer", "Network Architect", "Telecommunications Technician", "Network Administrator", "VoIP Engineer (Voice over Internet Protocol Engineer)", "RF Engineer (Radio Frequency Engineer)", "Telecommunications Analyst", "Fiber Optic Technician", "Sales Account Manager (Telecom)", "Telecommunications Project Manager"
        ]
    },

    {
        name: 'Agriculture',
        titles: [
            "Agricultural Engineer", "Farm Manager", "Agronomist", "Horticulturist", "Soil and Water Conservationist", "Crop Consultant", "Agricultural Technician", "Irrigation Specialist", "Agricultural Economist", "Livestock Manager"
        ]
    },

    {
        name: 'Construction',
        titles: [
            "Construction Manager", "Site Supervisor", "Civil Engineer", "Construction Estimator", "Project Manager (Construction)", "Architectural Drafter", "Quantity Surveyor", "Health and Safety Officer (Construction)", "Mason/Bricklayer", "Structural Engineer",
        ]
    },

    {
        name: 'Education',
        titles: [
            "Teacher", "School Principal", "Academic Coordinator", "Education Consultant", "Curriculum Developer", "Special Education Teacher", "Guidance Counselor", "Instructional Designer", "Education Administrator", "Librarian",
        ]
    }
]

</script>

<style scoped>
.label-transparent {
    display: none;
}
</style>