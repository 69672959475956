<template>
        <phoneContainer >
            <MobileNavbar></MobileNavbar>
            <div class="flex w-full h-full flex-col relative p-[24px_16px]">
                <div class="flex w-full flex-col grow-[1] justify-center items-center">
                    <img class="w-[180px] h-[auto] small-pulse" :style="{'animation-delay':'0s'}" src="./../assets/icons/finalizing.png"/>

                    <div class="flex flex-col w-full gap-[12px] pt-[24px]">
                        <div class="text-[24px] text-center font-bolder">Finalizing your account setup</div>
                        <div class="text-[14px] text-center">Process can take a couple of minutes. We thank you for your patience.</div>
                    </div>

                </div>
                <div class="flex flex-col gap-[12px] w-full pb-[42px]">
                        <div class="text-[#7C7A82] items-center p-[16px] rounded-md flex flex-col gap-[4px] justify-start items-start text-[12px]">
                            <div>Fun fact:</div>
                            <div class="text-center">“The New York Stock Exchange is considered to be the most traditional as traders cannot enter the floor of the exchange if they are not wearing a suit and tie.”</div>
                        </div>
                </div>
            </div>

        </phoneContainer>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import phoneContainer from '@/components/container/PhoneContainer.vue';
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import { useRouter } from 'vue-router';
import btnForm from "@/components/form/btn-form.vue";
import Modal from "./../components/mini_components/ModalContent.vue"
const router = useRouter()

onMounted(()=>{
    setTimeout(()=>{
        router.push({name:'complete'})

    },5000)
})

</script>