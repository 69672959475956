<template lang="">
    <PhoneContainer>
      <MobileNavbar />
      <BackNav3 :routeTo="'term2'"></BackNav3>

      <div class="flex flex-col p-[16px] h-full">
        <div class="w-full">
            <h2 class="text-[24px] mt-[12px] font-bold pt-[60px]">What is your current employment status?</h2>
        </div>

        <div class="w-full grow-[1]">
            <div class="mb-[16px] mt-[20px] relative">
            <router-link :to="{path: '/employmentdetails'}" v-slot="{href, navigate}">
              <button :href="href" @click="navigate" class="w-full h-full bg-gray-100 flex flex-row justify-between p-[24px] text-[16px] rounded-[12px]">
                Employed
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967Z" fill="#3C70FF"/>
                  </svg>
              </button>
           </router-link>
        </div>

            <div class="mb-[16px] mt-[16px] relative">
              <button class="w-full h-full bg-gray-100 flex flex-row justify-between p-[24px] text-[16px] rounded-[12px]" @click="router.push({name:'employmentdetails'})">
                Self-employed
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967Z" fill="#3C70FF"/>
                  </svg>
              </button>
            </div>

            <div class="mb-[16px] mt-[16px] relative">
              <button class="w-full h-full bg-gray-100 flex flex-row justify-between p-[24px] text-[16px] rounded-[12px]" @click="router.push({name:'sourceofIncome'})">
              Not employed
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967Z" fill="#3C70FF"/>
                </svg>
              </button>
            </div>

        </div>
      </div>

      <RevModal v-if="isMounted" :forRegions="regions" :type="modalType" :show="showModal"
            :header="'Select country'" @modal:close="showModal = false" @select:country="handleSelectedCountry"
            @select:city="handleSelectedCity" :cityHeader="'Select city'">
        </RevModal>

    </PhoneContainer>
  </template>

<script setup>
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import PhoneContainer from "@/components/container/PhoneContainer.vue";
import btnForm from "@/components/form/btn-form.vue";
import BackNav3 from "@/components/mini_components/BackNav3.vue";
import mapbutton from '@/assets/mapbutton.png';
import map5 from '@/assets/map5.png';
import map6 from '@/assets/map6.png';
import headerForm from '@/components/form/header-form.vue';
import inputForm from "@/components/form/input-form.vue";
import { useRouter } from 'vue-router';
const router = useRouter()


</script>

<style scoped>
.label-transparent {
  display: none;
}
</style>