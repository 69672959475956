<template>
 <button :disabled="$Props.btnDisabled" type="submit" 
 class="flex gap-[6px] justify-center items-center w-full text-[16px] font-[600] text-white border-2 border-[var(--blue)] bg-[var(--blue)] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
 :class="{
    '!bg-[transparent] !text-[var(--black)] !border-[var(--black)]':($Props.theme=='2'),
    '!bg-white !text-[var(--blue)] !border-white':($Props.theme=='3'),
 }"
 ><slot></slot></button>
</template> 

<script setup>
import {defineProps} from 'vue'

const $Props = defineProps({
    theme : {default:'1'},
    btnDisabled: {default: false}

})

</script>
<style scoped>
button:disabled{
    background-color:#CCCBD1!important;
    outline:#CCCBD1!important;
    border-color:#CCCBD1!important;
}

</style>