<template lang="">
  <PhoneContainer>
    <MobileNavbar />
    <div class="mt-[60px] px-[12px] relative" >
      <div class="flex gap-[5px] mb-[15px]">
        <div
          v-for="(progress, index) in progressBars"
          :key="index"
          class="w-full bg-gray-200 rounded-full h-[4px]"
        >
          <div
            class="bg-blue-600 h-[4px] transition-all rounded-full"
            :style="{ width: progress + '%' }"
          ></div>
        </div>
      </div>
      <div class="flex justify-center items-center  mt-[5vmin]">
        <img v-if="currentLoadImage == 0" src="../assets/featuredImages/1.jpg" class="w-[auto]  h-[25vh]">
        <img v-else-if="currentLoadImage == 1" src="../assets/featuredImages/2.jpg" class="w-[auto]  h-[25vh]">
        <img  v-else-if="currentLoadImage == 2" src="../assets/featuredImages/3.jpg" class="w-[auto]  h-[25vh]">
        <img  v-else-if="currentLoadImage == 3" src="../assets/featuredImages/4.jpg" class="w-[auto]  h-[25vh]">
      </div>
      <div class="mt-[30px]">
        <div v-if="currentLoadImage == 0" class="text-center">
          <p class="text-[24px] mb-[5px] " style="font-weight:900;">Invest in Stocks and Derivatives</p>
          <p class="text-[14px]">Our intuitive platform offers <br> the most competitive rates in the market </p>
        </div>
        <div v-else-if="currentLoadImage == 1"  class="text-center">
          <p class="text-[24px] mb-[5px] " style="font-weight:900;">Invest in Stocks and Derivatives</p>
          <p class="text-[14px]">All you need is your Emirates ID <br> and/or passport and proof of address</p>
        </div>
        <div  v-else-if="currentLoadImage == 2" class="text-center">
          <p class="text-[24px] mb-[5px] " style="font-weight:900;">Fast Deposits & Withdrawals</p>
          <p class="text-[14px]">Top up your wallet from several transfer <br> options and easily withdraw your money </p>
        </div>
        <div  v-else-if="currentLoadImage == 3" class="text-center">
          <p class="text-[24px] mb-[5px] " style="font-weight:900;">Directly Subscribe to IPOs</p>
          <p class="text-[14px]">Your account will reflect the allocated shares, <br> which you can trade immediately </p>
        </div>
      </div>
   
    </div>
    <div class="absolute p-[16px] w-full bottom-[0] grid gap-[12px]">
      <btnForm class="small-pulse" @click="sign_up()">Sign-up</btnForm>
      <btnForm theme="2">Sign-in</btnForm>
    </div>
  </PhoneContainer>
</template>
<script setup>
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import PhoneContainer from "@/components/container/PhoneContainer.vue";
import btnForm from "@/components/form/btn-form.vue";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const progressBars = ref([0, 0, 0, 0]); 
const currentLoadImage = ref(0)

const loadProgress = async () => {
  for (let i = 0; i < progressBars.value.length; i++) {
    currentLoadImage.value = i 
    await loadSingleProgressBar(i);
  }
};
const sign_up = () => {
  router.push({ name: 'signup' })
  
};
const loadSingleProgressBar = (index) => {
  return new Promise((resolve) => {
    let progress = 0;
    const interval = setInterval(() => {
      if (progress < 100) {
        progress += 1; 
        progressBars.value[index] = progress;
      } else {
        clearInterval(interval);
        resolve();
      }
    }, 50); 
  });
};


onMounted(() => {
  loadProgress();
});
</script>
