<template>
    <div class="flex justify-center items-center min-h-screen bg-gray-200 relative mobileCheckCont">
        <div class="mobileCheck bg-white rounded-[30px] borderCustom shadow-lg relative overflow-hidden">
            <div id="phoneFrame" class="overflow-auto h-full w-full ">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<style scoped>
.mobileCheck {
    height: 90vh;
    width: 44vmin;
}
.borderCustom {
    border: 1vmin solid #D1D5DB;
}

@media (orientation: portrait) and (max-width: 768px) {
    .mobileCheck {
        top:0px;
        height:100dvh;
        width: 100%;
        border-radius:0px!important;
        position:absolute;
    }

    .borderCustom {
        border: 0 solid #D1D5DB;
    }
}

@media (orientation: portrait) and (max-width: 480px) {
    .mobileCheckCont{
        height: 100%;
       
    }
    .mobileCheck {
        top:0px;
        height:100dvh;
        width: 100%;
        border-radius:0px!important;
        position:fixed;
    }

    .borderCustom {
        border: 0 solid #D1D5DB;
    }
}
</style>