<template>
    <div :class="textColor === 'black' ? 'bg-white':'bg-transparent'"
        class="bg-transparent max-h-[30px] hideOnmobile h-[30px] overflow-hidden w-full px-[20px] flex justify-between py-[1px] items-center absolute top-0 " style="z-index: 3;">
        <p class="font-bold text-white text-[12px]" :style="`color:${textColor}`">{{ currentTime }}</p>
        <div class="flex justify-end items-center gap-[4px]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-[20px] text-white" :style="`color:${textColor}`">
                <path fill-rule="evenodd"
                    d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.062 0 8.25 8.25 0 0 0-11.667 0 .75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.204 3.182a6 6 0 0 1 8.486 0 .75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.061 0 3.75 3.75 0 0 0-5.304 0 .75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.182 3.182a1.5 1.5 0 0 1 2.122 0 .75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.061 0l-.53-.53a.75.75 0 0 1 0-1.06Z"
                    clip-rule="evenodd" />
            </svg>

            <svg class="w-[20px] text-white" :style="`fill:${textColor}`" viewBox="0 0 25 24"  xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M22 4.5a.8.8 0 0 1 .8.8v13.401a.8.8 0 1 1-1.6 0V5.3a.8.8 0 0 1 .8-.8ZM3.599 15.22a.8.8 0 0 1 .8.8v2.68a.8.8 0 0 1-1.6 0v-2.68a.8.8 0 0 1 .8-.8ZM9 13.34a.8.8 0 0 0-1.6 0v5.361a.8.8 0 0 0 1.6 0v-5.36ZM12.8 9.86a.8.8 0 0 1 .8.8v8.04a.8.8 0 1 1-1.6 0v-8.04a.8.8 0 0 1 .8-.8ZM18.2 7.98a.8.8 0 0 0-1.6 0v10.721a.8.8 0 0 0 1.6 0V7.981Z" />
            </svg>
            <svg data-slot="icon" fill="currentColor" class="w-[23px] text-white"  :style="`color:${textColor}`" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clip-rule="evenodd" fill-rule="evenodd"
                    d="M3.75 6.75a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-.037c.856-.174 1.5-.93 1.5-1.838v-2.25c0-.907-.644-1.664-1.5-1.837V9.75a3 3 0 0 0-3-3h-15Zm15 1.5a1.5 1.5 0 0 1 1.5 1.5v6a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-6a1.5 1.5 0 0 1 1.5-1.5h15ZM4.5 9.75a.75.75 0 0 0-.75.75V15c0 .414.336.75.75.75H18a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-.75-.75H4.5Z">
                </path>
            </svg>
        </div>
    </div>

</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps} from 'vue';

const props = defineProps({
  textColor: {
    type: String,
    default: 'black'
  }
})

const currentTime = ref('');

function updateCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12 || 12;

    currentTime.value = `${hours}:${minutes} ${amPm}`;
}

let timer;

onMounted(() => {
    updateCurrentTime(); // Update time immediately
    timer = setInterval(updateCurrentTime, 1000); // Update every second
    console.log(props.textColor)
    
});

onUnmounted(() => {
    clearInterval(timer); // Clean up when component is destroyed
});
</script>

<style scoped>
.hideOnmobile {
    z-index: 2;
    display: flex;
}

@media (orientation: portrait) and (max-width: 768px) {
    .hideOnmobile {
        display: none;
    }
}
</style>
