<template>
    <teleport to="#phoneFrame">
        <div v-if="$Options.show" class="w-full h-full absolute top-[0px] left-[0px] bg-[rgba(0,0,0,.5)] z-[9999] flex justify-center items-center">
            <div class="bg-white w-[calc(100%-32px)] p-[24px] rounded-md flex flex-col gap-[12px]">
                    <div class="title text-[24px] font-bolder text-center"><slot name="title"></slot></div>
                    <div class="body text-[14px] text-[#616068]"><slot name="body"></slot></div>
                    <div class="footer"><slot name="footer"></slot></div>
            </div>
        </div>
    </teleport>
</template>

<script setup>

import {ref,defineExpose} from 'vue'

const $Options = ref({
    show : false
})
const _fn = {
    show : ()=>{
        $Options.value.show = true
    },
    hide : ()=>{
        $Options.value.show = false
    }
}
defineExpose({
    _fn : _fn
})
</script>