<template>

    <phoneContainer>

        <MobileNavbar></MobileNavbar>

        <div>
            <div class=" flex justify-end mt-10">
                <img src="./../assets/verify_identity/righticon.png">
            </div>


            <div class="flex flex-col items-center justify-center h-[50vmin]">
                <img src="./../assets/verify_identity/Isolation_Mode.png">
                <p class="text-[3vmin]">Scan Emirates ID</p>
                <p class="text-center text-sm p-4 text-gray-400">UAE Passport holders, please proceed with onboarding
                    using your
                    Emirates
                    ID.
                    Alternatively, you can
                    opt to register via UAE pass to avoid NFC verification.</p>
            </div>
        </div>

        <div class="flex flex-col items-center justify-center">
            <button class="bg-blue-500 text-white p-3 w-[30vmin] rounded-md mt-[10vmin] flex items-center">
                <img src="./../assets/verify_identity/mini_icon1.png" class="w-6 h-6 mr-2">Continue with Emirates ID
            </button>

            <button class="bg-white text-blue-500 p-3 w-[30vmin] rounded-md mt-1 flex items-center">
                <img src="./../assets/verify_identity/UAEPASS_Logo.png" class="w-6 h-6 mr-2">Continue with UAE PASS
            </button>
        </div>

    </phoneContainer>

</template>

<script setup>
import { reactive, ref } from 'vue';
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import "intl-tel-input/styles";

const showContact = ref(false)
const modalEffect = ref(null)
const showContactSupport = () => {
    showContact.value = !showContact.value;
    if (showContact.value) {
        modalEffect.value = "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center";
    }
}
const close = () => {
    showContact.value = false
    modalEffect.value = ""
}







</script>

<style scoped>
.mobileCheck {
    height: 90vh;
    width: 44vmin;
}

.borderCustom {
    border: 1vmin solid #D1D5DB;
}

@media (orientation: portrait) and (max-width: 768px) {
    .mobileCheck {
        height: 100vh;
        width: 100vw;

    }

    .borderCustom {
        border: 0 solid #D1D5DB;
    }
}

@media (orientation: portrait) and (max-width: 480px) {
    .mobileCheck {
        height: 100vh;
        width: 100vw;
    }

    .borderCustom {
        border: 0 solid #D1D5DB;
    }
}
</style>
