<template>

    <phoneContainer>

        <MobileNavbar></MobileNavbar>

        <div>
            <div class=" flex justify-end mt-10">
                <img src="./../assets/verify_identity/righticon.png" @click="showContactSupport()">
            </div>


            <div class="flex flex-col items-center justify-center h-[50vmin]">
                <img src="./../assets/verify_identity/96.png">
                <p class="text-[3vmin]">Let’s try again</p>
                <p>Your selfie doesn’t match the photo on the ID</p>
            </div>
        </div>
        <div :class="`${modalEffect}`">
            <div v-if="showContact"
                class="fixed items-center justify-center  left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                <div class="bg-white h-[30vmin] w-[35vmin] shadow-md rounded-md">
                    <p class="text-[3vmin] p-2">Contact Support <button
                            class="float-right   w-7 h-7 bg-gray-400 border-1 rounded-full text-[2vmin] text-white"
                            @click="close">x</button>
                    </p>


                    <div class="flex items-center p-2">
                        <img src="./../assets/verify_identity/icon1.png" class="mr-2">
                        <div>
                            <p>Email Us</p>
                            <p>support@xcube.ae</p>
                        </div>
                    </div>

                    <div class="flex items-center p-2">
                        <img src="./../assets/verify_identity/icon2.png" class="mr-2">
                        <p>Call Us</p>
                        <p>Mon–Fri / 9:00am–6:00pm</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="flex flex-col items-center justify-center ">
            <router-link :to="{ path: '/Verify_Indentity_confirmation', query: { userid: 'true' } }"
                v-slot="{ href, navigate }">

                <button :href="href" @click="navigate"
                    class="bg-blue-500 text-white p-3 w-[20vmin] rounded-md mt-[10vmin]">Scan ID
                    again</button>
            </router-link>

            <router-link :to="{ path: '/verify_offline' }" v-slot="{ href, navigate }">
                <button :href="href" @click="navigate"
                    class="bg-white text-blue-500 p-3 w-[20vmin] rounded-md mt-1">Verify offline</button>
            </router-link>

        </div>

    </phoneContainer>

</template>

<script setup>
import { reactive, ref } from 'vue';
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import "intl-tel-input/styles";

const showContact = ref(false)
const modalEffect = ref(null)
const showContactSupport = () => {
    showContact.value = !showContact.value;
    if (showContact.value) {
        modalEffect.value = "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center";
    }
}
const close = () => {
    showContact.value = false
    modalEffect.value = ""
}







</script>

<style scoped>
.mobileCheck {
    height: 90vh;
    width: 44vmin;
}

.borderCustom {
    border: 1vmin solid #D1D5DB;
}

@media (orientation: portrait) and (max-width: 768px) {
    .mobileCheck {
        height: 100vh;
        width: 100vw;

    }

    .borderCustom {
        border: 0 solid #D1D5DB;
    }
}

@media (orientation: portrait) and (max-width: 480px) {
    .mobileCheck {
        height: 100vh;
        width: 100vw;
    }

    .borderCustom {
        border: 0 solid #D1D5DB;
    }
}
</style>
