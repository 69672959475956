<template lang="">
  <PhoneContainer>
    <MobileNavbar />
    <BackNav></BackNav>

    <div class="flex flex-col p-[16px] h-full">
      <div class="w-full">
          <h2 class="text-[24px] font-bold pt-[60px]">Address Details</h2>
          <p class="text-[14x] text-gray-400">Please provide your residential address details</p>
          
          <router-link :to="{path: '/allowlocation'}" v-slot="{href, navigate}">
          <button @click="navigate">
              <div class="mt-[12px] w-full relative">
                  <img :src="map5" alt="Logo" class="w-[780px]">
                  
              </div>
          </button>
          </router-link>
      </div>
      <div class="w-full grow-[1]">
          <div class="mb-[16px] mt-[20px] relative">
              <inputForm :label="'Country'" @click="handleOpenModal('country')" :inputModel="country" placeholder="United Arab Emerates"> 
              </inputForm>
              <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 1024 1024"
                  class="icon absolute top-[60%] right-[12px]" version="1.1">
                  <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                      fill="#3C70FF" />
              </svg>
          </div>
          <div class="mb-[24px] relative">
              <inputForm :label="'City'" @click="handleOpenModal('city')" :inputModel="city" placeholder="Dubai"></inputForm>
              <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 1024 1024"
                  class="icon absolute top-[60%] right-[12px]" version="1.1">
                  <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                      fill="#3C70FF" />
              </svg>
          </div>
          <div class="mb-[16px]">
              <region-select class="w-full select-region" v-model="region" :country="code" :region="region" />
          </div>
      
          <div class="mb-[24px] relative">
              <inputForm :label="'Address'" placeholder="34H9+RX"></inputForm>
          </div>
      </div>
      <div class="w-full pb-[16px]">
          <router-link :to="{path: '/addressdetails3'}" v-slot="{href, navigate}">
          <button :href="href" 
              class="w-full bg-[#3C70FF] text-white py-3 rounded-lg font-medium hover:bg-[#3361E6] transition" @click="navigate">
              Continue
              </button>
          </router-link>
      </div>
  
    </div>

    <RevModal v-if="isMounted" :forRegions="regions" :type="modalType" :show="showModal"
          :header="'Select country'" @modal:close="showModal = false" @select:country="handleSelectedCountry"
          @select:city="handleSelectedCity" :cityHeader="'Select city'">
      </RevModal>

  </PhoneContainer>
</template>

<script setup>
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import PhoneContainer from "@/components/container/PhoneContainer.vue";
import btnForm from "@/components/form/btn-form.vue";
import BackNav from "@/components/mini_components/BackNav.vue";
import mapbutton from '@/assets/mapbutton.png';
import map5 from '@/assets/map5.png';
import map6 from '@/assets/map6.png';
import headerForm from '@/components/form/header-form.vue';
import inputForm from "@/components/form/input-form.vue";
import { ref, onMounted, watch, nextTick, onUnmounted } from "vue";
import { CountrySelect, RegionSelect, CountryRegionData } from 'vue3-country-region-select';
import 'flag-icons/css/flag-icons.min.css';
import RevModal from '@/components/mini_components/RevModal.vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();

const img_src = ref(null);
const defaultDisplay = ref(true);

const region = ref(null);
const code = ref('');
const country = ref('');
const city = ref('');
const isMounted = ref(false);
const showModal = ref(false);
const regions = ref([]);
const selectElement = ref(null);
const modalType = ref('');

watch(code, (newCountry) => {
  console.log('Selected Country:', newCountry);
});

const handleOpenModal = (type) => {
  modalType.value = type;
  showModal.value = true;
}

const updateRegions = () => {
  if (selectElement.value) {
      regions.value = [];
      selectElement.value.options.forEach((element, index) => {
          if (index !== 0) {
              regions.value.push(element.textContent);
          }
      });
      console.log("regions:::: ", regions.value);
  }
};

const handleSelectedCountry = async (data) => {
  await nextTick();
  code.value = data.code;
  country.value = data.country;
  selectElement.value = document.querySelector('.select-region');
};


const handleSelectedCity = (data) => {
  city.value = data;
};

onMounted(async () => {
  await nextTick();

  isMounted.value = true;
  selectElement.value = document.querySelector('.select-region');
  selectElement.value.classList.add('label-transparent');

  updateRegions();

  const observer = new MutationObserver(() => {
      updateRegions();
  });

  if (selectElement.value) {
      observer.observe(selectElement.value, { childList: true, subtree: false });
  }

  onUnmounted(() => {
      observer.disconnect();
  });

  img_src.value = mapbutton;
  

  if(route.query.location == 2){
    img_src.value = map5;
    defaultDisplay.value = false;
  } else if(route.query.location == 3){
    img_src.value = map6;
    defaultDisplay.value = false;
  }
});
</script>

<style scoped>

.label-transparent {
  display: none;
}

inputform input::placeholder {
  color: black;
}
</style>