<template>
  <div class="relative">
    <phoneContainer>
      <MobileNavbar></MobileNavbar>
      <div class="pt-[24px] w-full mt-[13px]">
        <div
          class="w-[calc(20%+32px)] h-[4px] bg-[var(--blue)] rounded mx-[-16px]"
        ></div>
      </div>
      <div class="flex justify-between mt-[16px] px-[13px]">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-[25px] text-blue-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </div>
        <div class="font-bold text-[16px]">Personal details</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div class="px-[13px] py-[10px] top-[8px]">
        <p class="text-[24px] font-bold">Provide details of your employment</p>
        <div v-if="validSelections.includes(1)">
          <p class="text-[16px] mt-[15px] mb-[24px] font-bold">Exchange(s)</p>
          <div
            id="container  mb-[6px]"
            class="gap-[24px] grid"
            v-for="(item, idx) in data"
            :key="item.id"
            :class="item.extype == 'ex' ? '' : 'hidden'"
          >
            <div>
        
              <inputForm
               label="Exchange name"
                :inputModel="item.exchangeName"
                @inputModel:update="
                  (res) => {
                    item.exchangeName = res;
                  }
                "
              ></inputForm>
            </div>
            <div>
           
              <inputForm
                label="Role/position"
                :inputModel="item.role"
                @inputModel:update="
                  (res) => {
                    item.role = res;
                  }
                "
              ></inputForm>
            </div>
          
            <div v-if="data.length > 1" class="flex pt-[11px] justify-end">
              <button
                @click="() => addRemoveData(idx, 'remove', null)"
                class="text-[#FF1A40] text-[14px]"
              >
                Remove
              </button>
            </div>
          </div>
           
    
        </div>
    
        <div v-if="validSelections.includes(1)" class="py-[15px] border-t-[1px] mt-[15px]">
          <button
            @click="addRemoveData(null, 'add', 'ex')"
            class="text-[var(--blue)] text-[14px]"
          >
            Add another exchange
          </button>
        </div>
        <div  v-if="validSelections.includes(2)">
          <p class="text-[16px] mt-[15px] mb-[24px] font-bold">Listed company(-ies)</p>
          <div
            id="container  mb-[6px]"
            class="gap-[24px] grid"
            v-for="(item, idx) in data"
            :key="item.id"
            :class="item.extype == 'list' ? '' : 'hidden'"
          >
            <div>
     
              <inputForm
                label="Listed company name"
                :inputModel="item.exchangeName"
                @inputModel:update="
                  (res) => {
                    item.exchangeName = res;
                  }
                "
              ></inputForm>
            </div>
            <div>
          
              <inputForm
                label="Role/position"
                :inputModel="item.role"
                @inputModel:update="
                  (res) => {
                    item.role = res;
                  }
                "
              ></inputForm>
            </div>
            <div v-if="data.length > 1" class="flex pt-[11px] justify-end">
              <button
                @click="() => addRemoveData(idx, 'remove', null)"
                class="text-[#FF1A40] text-[14px]"
              >
                Remove
              </button>
            </div>
          </div>
          
        </div>
        <div  v-if="validSelections.includes(2)" class="py-[15px] border-t-[1px] mt-[15px]">
          <button
            @click="addRemoveData(null, 'add', 'list')"
            class="text-[var(--blue)] text-[14px]"
          >
          Add another listed company
          </button>
        </div>
        <div  v-if="validSelections.includes(3)">
          <p class="text-[16px] mb-[24px] mt-[15px] font-bold">Financial institution(s)</p>
          <div
            id="container  mb-[6px]"
            class="gap-[24px] grid"
            v-for="(item, idx) in data"
            :key="item.id"
            :class="item.extype == 'fin' ? '' : 'hidden'"
          >
            <div>
           
              <inputForm
              label="Financial institution name"
                :inputModel="item.exchangeName"
                @inputModel:update="
                  (res) => {
                    item.exchangeName = res;
                  }
                "
              ></inputForm>
            </div>
            <div >
        
              <inputForm
               label="Role/position"
                :inputModel="item.role"
                @inputModel:update="
                  (res) => {
                    item.role = res;
                  }
                "
              ></inputForm>
            </div>
            <div v-if="data.length > 1" class="flex pt-[11px] justify-end">
              <button
                @click="() => addRemoveData(idx, 'remove', null)"
                class="text-[#FF1A40] text-[14px]"
              >
                Remove
              </button>
            </div>
          </div>
          <div  v-if="validSelections.includes(3)" class="py-[15px] border-t-[1px] mt-[15px]">
            <button
              @click="addRemoveData(null, 'add', 'fin')"
              class="text-[var(--blue)] text-[14px]"
            >
            Add another financial institution
            </button>
          </div>
        </div>
      
      </div>
      <div class="p-[16px]">
        <btnForm theme="1" :btn-disabled="isButtonDisabled" @click="router.push({name:'PersonalDetails_Provide'})">
          Continue
        </btnForm>
      </div>
    </phoneContainer>
  </div>
</template>

<script setup>
import phoneContainer from "@/components/container/PhoneContainer.vue";
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import inputForm from "@/components/form/input-form.vue";
import { ref, computed, onMounted } from "vue";
import btnForm from "@/components/form/btn-form.vue";

import { useRoute, useRouter } from 'vue-router';
const router = useRouter();


const data = ref([]);
const validSelections = ref([]);
onMounted(() => {
  // Get the URL fragment (after the #)
  const urlFragment = window.location.hash;

  // Extract the query string from the fragment
  const queryString = urlFragment.split("?")[1];

  // Use URLSearchParams to parse the query string
  const params = new URLSearchParams(queryString);

  // Get the value of the 'selectedsource' parameter
  const selectedsource = params.get("selectedsource");

  // Output the value to the console
  let arrNumbers = selectedsource.split(",").map(Number); // Convert each element to a number

  validSelections.value = arrNumbers;

  if (validSelections.value.includes(3)) {
    data.value.push({ id: 1, exchangeName: "", role: "", extype: "fin" });
  }
  if (validSelections.value.includes(2)) {
    data.value.push({ id: 2, exchangeName: "", role: "", extype: "list" });
  }
  if (validSelections.value.includes(1)) {
    data.value.push({ id: 3, exchangeName: "", role: "", extype: "ex" });
  }
});

const addRemoveData = (idx, reqType, ext) => {
  if (reqType === "add") {
    data.value.push({
      id: Date.now(), // Unique id for each entry
      exchangeName: "",
      role: "",
      extype: ext,
    });
  } else if (reqType === "remove") {
    data.value.splice(idx, 1);
  }
};

const isButtonDisabled = computed(() => {
  return (
    data.value.length === 0 ||
    data.value.some((item) => item.exchangeName === "" || item.role === "")
  );
});
</script>
