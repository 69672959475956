<template>

    <div class="flex justify-center items-center min-h-screen bg-gray-200 relative">
        <div class="mobileCheck bg-white rounded-[30px] borderCustom shadow-lg relative overflow-hidden ">
            <div id="phoneFrame" class="overflow-auto h-full w-full ">

                <MobileNavbar></MobileNavbar>
                <div class="w-full absolute px-[16px] top-[48px] text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path d="M5 12H19M5 12L11 6M5 12L11 18" />
                    </svg>
                </div>
                <div class="flex items-center justify-center text-white p-10">
                    Take a selfie
                </div>


                <div class="flex items-center justify-center text-white p-5  mt-[55vmin] text-center">
                    <p>Position your face inside the oval, then start recording</p>


                </div>
                <div class="flex items-center justify-center ">
                    <router-link :to="{ path: `reviewconfirm` }" v-slot="{ href, navigate }">

                        <button :href="href" @click="navigate"
                            class="rounded-full h-[10vmin] w-[10vmin] bg-red-600 border-[1vmin] border-white"></button>
                    </router-link>
                </div>

            </div>

        </div>

    </div>

</template>

<script setup>
import { reactive, ref, onMounted } from 'vue';
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import "intl-tel-input/styles";
import { useRoute } from 'vue-router';
const route = useRoute();
const path = ref(null)
const userId = route.query.userid;


onMounted(() => {
    if (userId) {
        path.value = '/scan_emirates'
    }
    else {
        path.value = '/Verify_Indentity_tryAgain1'
    }

})
</script>

<style scoped>
#phoneFrame {
    background-image: url('./../assets/verify_identity/overlay.jpg');
    background-size: cover;
    background-position: center;
}

.mobileCheck {
    height: 90vh;
    width: 44vmin;
}

.borderCustom {
    border: 1vmin solid #D1D5DB;
}

@media (orientation: portrait) and (max-width: 768px) {
    .mobileCheck {
        height: 100vh;
        width: 100vw;

    }

    .borderCustom {
        border: 0 solid #D1D5DB;
    }
}

@media (orientation: portrait) and (max-width: 480px) {
    .mobileCheck {
        height: 100vh;
        width: 100vw;
    }

    .borderCustom {
        border: 0 solid #D1D5DB;
    }
}
</style>
