<template>
  <div class="relative">
    <phoneContainer>
      <MobileNavbar></MobileNavbar>
      <div class="pt-[24px] w-full mt-[13px] ">
        <div
          class="w-[calc(20%+32px)] h-[4px] bg-[var(--blue)] rounded mx-[-16px]"
        ></div>
      </div>
      <div class="flex justify-between mt-[16px]  px-[13px]">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-[25px] text-blue-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </div>
        <div class="font-bold text-[16px]">Personal details</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div class="px-[13px] py-[10px] top-[8px]">
        <p class="text-[24px] font-bold">
          {{ instruction[insIndex].title }} New
        </p>
        <p class="text-[14px] mt-[10px] ">
          {{ instruction[insIndex].description }}
        </p>
      </div>
      <div class="px-[13px] delay-300 !duration-[5s] gap-[8px] grid mb-[100px] ">
        <div
        v-for="(items1, index) in question1"
        :key="index"
        :class="
          selectedSources.includes(items1.id)
            ? 'border-[1.5px] border-[var(--blue)] bg-blue-50'
            : 'border-[1.5px] border-opacity-0'
        "
        class="bg-gray-50 py-[20px] px-[20px] rounded-[8px] flex justify-between items-center cursor-pointer"
        @click="toggleCheckbox(items1.id)"
      >
        <div>
          <p class="text-[16px] mb-[2px]">{{ items1.desc }}</p>
        </div>
        <div>
          <div class="flex items-center space-x-2">
            <input
              :id="'checkbox-' + items1.id"
              type="checkbox"
              v-model="selectedSources"
              :value="items1.id"
              class="hidden"
            />
            <label
              :for="'checkbox-' + items1.id"
              class="blue-custom-checkbox-label"
            ></label>
          </div>
        </div>
      
      
        </div>
      </div>

      <div class="absolute w-full bottom-0 flex justify-center p-[16px]">
        <btnForm
          theme="1"
          class="bg-blue-500"
          @click="continueP()"
          :btn-disabled="selectedSources.length == 0"
        >
          Continue
        </btnForm>
      </div>
    </phoneContainer>
  </div>
</template>

<script setup>
import phoneContainer from "@/components/container/PhoneContainer.vue";
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import btnForm from "@/components/form/btn-form.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter()
const insIndex = ref(0);
const currentIncome = ref(1);
const question1 = ref([
  { id: 1, desc: "Yes, exchange" },
  { id: 2, desc: "Yes, listed company" },
  { id: 3, desc: "Yes, financial institution" },
  { id: 4, desc: "No, I’m not" },
]);
const selectedSources = ref([]);
const instruction = ref([
  {
    title:
      "Are you a board member or employed in any exchange/listed company/financial institution?",
    description: "You can select multiple options",
  },
  {
    title: "What is your estimated total net worth?",
    description:
      "Please select the option that best represents the total amount of assets you currently hold, minus your total outstanding liabilities",
  },
]);
const selectedIncome = ref();

const continueP = () => {
  const idsToCheck3 = [1, 2, 3]; // Replace with your desired IDs
  const idsToCheck2 = [1, 2];
  const idsToNone = [4];
  const hasAllId12 = idsToCheck2.every((id) =>
    selectedSources.value.includes(id)
  );
  const hasAllIds = idsToCheck3.every((id) =>
    selectedSources.value.includes(id)
  );
  const hasNoneId = idsToNone.every((id) => selectedSources.value.includes(id));

  if (hasAllIds) {
    router.push('proofofEmployment2/?selectedsource=' +selectedSources.value )
  } else if (
    selectedSources.value.length == 1 &&
    selectedSources.value.includes(1)
  ) {
    router.push({name: 'proofofEmployment'})
  } else if (hasAllId12) {
    router.push('proofofEmployment2/?selectedsource=' +selectedSources.value )
  } else if (hasNoneId) {
    //alert("path skip");
    router.push({name:'PersonalDetails_BoardMember'})
  } else {
    router.push('proofofEmployment2/?selectedsource=' +selectedSources.value )
  }
};

const checkNoneofthese = (value) => {
  if (value === 4) {
    selectedSources.value = [];
    selectedSources.value.push(value);
  } else {
    if (selectedSources.value.includes(4)) {
      const index = selectedSources.value.indexOf(4);
      if (index !== -1) {
        selectedSources.value.splice(index, 1);
      }
      selectedSources.value.push(value);
    }
  }
};
const toggleCheckbox = (id) => {
  const index = selectedSources.value.indexOf(id);
  if (index > -1) {
    selectedSources.value.splice(index, 1);
  } else {
    selectedSources.value.push(id);
  }
  
  checkNoneofthese(id); // Call your existing function
};


</script>
