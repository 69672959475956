<template>
    <!-- <div class="flex flex-col gap-[24px]">
        <inputForm :label="'Full name'"></inputForm>
        <inputForm :label="'Email address'" type="email"></inputForm>
        <inputForm :label="'Password'" type="password"></inputForm>
        <btnForm>Create an account</btnForm>
    </div> -->

    <phoneContainer>
        <MobileNavbar></MobileNavbar>
        <div class="flex flex-row gap-5 justify-center items-center mt-10" v-if="pageReady">
            <BackNav />
            <img class="w-[130px]" src="./../assets/nfc_logos/uqudologo.png" />

        </div>

        <div class="flex flex-row gap-5 justify-center items-center mt-10">
            <BackNav />
            <img class="w-[90%] max-w-[300px]" src="./../assets/nfc_logos/phoneid.jpg" />

        </div>

        <div class="content text-center">
            <p class="text-[20px] font-bold ">Scan contactless chip</p>
            <p>Place the back camera on ID Card for <br /><b>10 seconds</b> </p>


            <div class="justify-center pt-[20px]">
                <button class="text-[24px] font-bold " @click="ShowControl()">Start</button>
            </div>


        </div>

        <!-- Modal Background -->
         <div v-if="pageReady">
         <teleport to="#phoneFrame">
        <div v-if="ShowModal" class="absolute inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center p-[16px]">
            <!-- Modal Content -->
            <div class="bg-white w-full h-[400px] shadow-md rounded-md p-5">
                <div class="text-gray-500 text-center text-[20px]">Scan the clip</div>

                <div class="flex items-center justify-center">
                    <img src="./../assets/nfc_logos/Icon.png">

                </div>
                <p class="mt-2" v-if="ShowText">Hold your phone on top of your ID's chip for 10 seconds</p>
                <div class="loader mt-5" v-if="showload">
                    <div class="box-load1 m-1"></div>
                    <div class="box-load2 m-1"></div>
                    <div class="box-load3 m-1"></div>
                    <div class="box-load4 m-1"></div>
                    <div class="box-load5 m-1"></div>
                    <div class="box-load6 m-1"></div>
                    <div class="box-load7 m-1"></div>
                    <div class="box-load8 m-1"></div>
                    <div class="box-load9 m-1"></div>
                    <div class="box-load10 m-1"></div>

                </div>
                <div class="flex items-center justify-center">
                    <!-- <button class="bg-gray-300 p-5 rounded-md w-full mt-10 " @click="ShowModal=false">Cancel</button> -->
                    <button class="bg-gray-300 p-5 rounded-md w-full mt-10 ">Cancel</button>
                </div>

            </div>
        </div>
        </teleport>
        <teleport to="#phoneFrame">
        <div v-if="show2modal" class="absolute inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center p-[16px]">
            <!-- Modal Content -->
            <div class="bg-white w-full h-[400px] shadow-md rounded-md p-5">

                <div class="flex items-center justify-center">
                    <img src="./../assets/nfc_logos/check.png" class="mt-[100px]">

                </div>

            </div>
        </div>
        </teleport>
        </div>



    </phoneContainer>

</template>

<script setup>
import { reactive, ref, onMounted } from 'vue';
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import "intl-tel-input/styles";
import { useRouter } from 'vue-router';

const pageReady = ref(false)
const router = useRouter()

const ShowModal = ref(false)
const showload = ref(false)
const ShowText = ref(true)
const show2modal = ref(false)
const ShowControl = () => {
    ShowModal.value = true
    
    if (ShowModal) {
       let firstInterval = setInterval(() => {
            ShowText.value = false
            showload.value = true
        }, 1000);


        let secondInterval = setInterval(() => {
            clearInterval(firstInterval)
            ShowModal.value = false
            show2modal.value = true
            let tm = setTimeout(() => {
                router.push({name:'Verify_Indentity'})
                clearTimeout(tm)
                clearInterval(secondInterval)
            }, 1000);
          
        }, 10000);
    }

}
onMounted(() => {
    pageReady.value = true
})



</script>

<style scoped>
.bg-active {
    background-color: blue;
}

.iti__tel-input:focus {
    background-color: white;
    caret-color: #007bff;
    outline: none;
}

.iti__tel-input {
    transition: all 0.3s ease;
}

.loader {
    display: flex;
    transition: all 0.5s;
}

.loader div {
    background-color: rgb(19, 102, 236);
    box-shadow: inset 2px 2px 10px rgb(10, 62, 167);
    border-radius: 100%;
    height: 30px;
    width: 30px;
}

.box-load1 {
    animation: brighten 1.2s infinite;
}

.box-load2 {
    animation: brighten 1.2s infinite;
    animation-delay: 0.1s;
}

.box-load3 {
    animation: brighten 1.2s infinite;
    animation-delay: 0.2s;
}

.box-load4 {
    animation: brighten 1.2s infinite;
    animation-delay: 0.3s;
}

.box-load5 {
    animation: brighten 1.2s infinite;
    animation-delay: 0.4s;
}

.box-load6 {
    animation: brighten 1.2s infinite;
    animation-delay: 0.5s;
}

.box-load7 {
    animation: brighten 1.2s infinite;
    animation-delay: 0.6s;
}

.box-load8 {
    animation: brighten 1.2s infinite;
    animation-delay: 0.7s;
}

.box-load9 {
    animation: brighten 1.2s infinite;
    animation-delay: 0.8s;
}

.box-load10 {
    animation: brighten 1.2s infinite;
    animation-delay: 0.9s;
}

@keyframes brighten {
    100% {
        background-color: rgb(255, 255, 255);
        box-shadow: inset 2px 2px 10px rgb(209, 210, 211);
    }
}
</style>
