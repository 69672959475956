<template>

    <phoneContainer>
        <MobileNavbar></MobileNavbar>
        <img v-show="$state == 1" src="./../assets/verify_identity/overlay-arabicF1/2B.jpg"
            class="w-full h-full absolute top-[0px] left-[0px] z-[1]" />
        <img v-show="$state == 0" src="./../assets/verify_identity/overlay-arabicF1/1B.jpg"
            class="w-full h-full absolute top-[0px] left-[0px] z-[1]" />
        <div class="w-full absolute px-[16px] top-[48px] text-white">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" stroke-width="2">
                <path d="M5 12H19M5 12L11 6M5 12L11 18" />
            </svg>
        </div>
        <div
            class="flex items-center justify-center z-[20] absolute left-[50%] translate-x-[-50%] top-[24px] w-full p-[16px] text-[16px] text-white">
            <p class="w-full text-center"> Take a selfie </p>
        </div>

        <div
            class="flex items-center justify-center z-[20] absolute left-[50%] translate-x-[-50%] bottom-[100px] w-full p-[16px] text-[16px] text-white">
            <p class="w-full text-center">Position your face inside the oval, then start recording</p>
        </div>
        <div class="flex items-center justify-center z-[20] absolute left-[50%] translate-x-[-50%] bottom-[24px]">
            <!-- <router-link :to="{ path: '/Verify_Indentity_failed' }" v-slot="{ href, navigate }">
                        <button :href="href" @click="navigate"
                            class="rounded-full h-[62px] w-[62px] bg-red-600 border-white border-[8px]"></button>
                    </router-link> -->
            <button :href="href" @click="_fn.start()"
                class="rounded-full h-[62px] w-[62px] bg-red-600 border-white border-[8px]"></button>

        </div>
        <div v-if="pageReady">
            <Modal ref="$ErrorModal">
                <template v-slot:title>Facial Verification failed</template>
                <template v-slot:body>
                    <p>Here are some tips:</p>
                    <ul class="list-decimal pl-[24px]">
                        <li>Your face needs to fit inside the oval and you must look straight into the camera</li>
                        <li>Remove anything that is covering your face, such as a mask</li>
                        <li>You cannot wear sunglasses or eyeglasses with dark lenses</li>
                        <li>Make sure the lighting is not too bright or too dark</li>
                    </ul>
                </template>
                <template v-slot:footer>
                    <btnForm @click="_fn.retry()">Try again</btnForm>
                </template>
            </Modal>
        </div>

    </phoneContainer>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue';
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import Modal from "./../components/mini_components/ModalContent.vue"
import btnForm from "@/components/form/btn-form.vue";
const pageReady = ref(false)
const $ErrorModal = ref(null)
const $state = ref(0)
import { useRouter } from 'vue-router'; // Import useRouter
const router = useRouter(); // Use useRouter here
const _fn = {
    start: () => {
        if ($state.value == 0) {
            _fn.showError()
            return
        }
        router.push({ name: 'reviewconfirm' })
    },
    showError: () => {
        $ErrorModal.value._fn.show()
    },
    retry: () => {
        $ErrorModal.value._fn.hide()
        $state.value = 1;
    }
}

onMounted(() => {
    pageReady.value = true
})
</script>

<style scoped></style>
