<template>

    <phoneContainer>

        <MobileNavbar></MobileNavbar>
        <div>
            <div class="flex justify-between mt-10 p-5 text-blue-600">
                <div class="text-black">
                    <p class="text-2xl pb-2">Offline verification</p>
                    <p class="text-sm">Our team is glad to help with the verification.
                        <br />If you face some problems in the
                        app
                    </p>
                </div>
                <router-link :to="{ path: '/lets_try_again' }" v-slot="{ href, navigate }">
                    <button :href="href" @click="navigate" class="font-bold">X</button>
                </router-link>
                <!-- <img src="./../assets/verify_identity/righticon.png" @click="showContactSupport()"> -->
            </div>


        </div>
        <div class="w-full relative">
            <img src="./../assets/verify_identity/img.png" class="w-full">
            <div class="absolute inset-0 flex items-center justify-center">
                <div class="bg-blue-400 text-white p-2 rounded-full">Sheikh Zayed Rd - Trade Centre - Dubai</div>
            </div>
        </div>

        <div class=" flex justify-between bg-gray-200 m-5">
            <div class="p-4 ">
                <p>Sun–Thu</p>
                <p>9 AM – 6 PM</p>
            </div>


            <div class="p-4 ">
                <p>Fri–Sat</p>
                <p>9 AM – 12 PM</p>
            </div>
        </div>


        <div class="p-5">
            Closes in 1 hour 26 minutes

        </div>

        <div class="w-full flex flex-col items-center p-5 ">
            <p class="text-center mb-4">See directions in navigation app</p>
            <div class="flex">
                <img src="./../assets/verify_identity/map1.png" class="mr-2">
                <img src="./../assets/verify_identity/map1.png">
            </div>
        </div>












    </phoneContainer>

</template>

<script setup>
import { reactive, ref } from 'vue';
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import "intl-tel-input/styles";

const showContact = ref(false)
const modalEffect = ref(null)
const showContactSupport = () => {
    showContact.value = !showContact.value;
    if (showContact.value) {
        modalEffect.value = "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center";
    }
}
const close = () => {
    showContact.value = false
    modalEffect.value = ""
}







</script>

<style scoped>
.mobileCheck {
    height: 90vh;
    width: 44vmin;
}

.borderCustom {
    border: 1vmin solid #D1D5DB;
}

@media (orientation: portrait) and (max-width: 768px) {
    .mobileCheck {
        height: 100vh;
        width: 100vw;

    }

    .borderCustom {
        border: 0 solid #D1D5DB;
    }
}

@media (orientation: portrait) and (max-width: 480px) {
    .mobileCheck {
        height: 100vh;
        width: 100vw;
    }

    .borderCustom {
        border: 0 solid #D1D5DB;
    }
}
</style>
