<template>
    <PhoneContainer>
        <MobileNavbar></MobileNavbar>

        <div class="flex flex-col items-center">
            <div class="flex items-center w-full mt-5">

                <div class="p-4 text-blue-400">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2" class="mr-2">
                        <path d="M5 12H19M5 12L11 6M5 12L11 18" />
                    </svg>
                </div>
                <!-- Paragraph centered between BackNav and the right side -->
                <div class="flex-1 text-center ml-[-50px] font-bold">
                    <p>Personal details</p>
                </div>

            </div>
            <div class="relative flex flex-col gap-[16px]">
                <div class="pt-6 text-left text-[24px] leading-8 font-bold p-5">
                    Are you a board member or employed in any exchange/listed company/financial institution?
                </div>

                <div class="flex items-center justify-between bg-[#f5f5f7]  p-5 m-5 rounded-[8px]"
                    @click="popUpModalElem._fn.show()">
                    <span class="text-[16px]">Yes, exchange</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967Z"
                            fill="#3C70FF" />
                    </svg>
                </div>

                <div class="flex items-center justify-between bg-[#f5f5f7]  mt-[-20px] p-5 m-5 rounded-[8px]"
                    @click="popUpModalElem._fn.show()">

                    <span class="text-[16px]">Yes, listed company</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967Z"
                            fill="#3C70FF" />
                    </svg>
                </div>
                <div class="flex items-center justify-between bg-[#f5f5f7]  mt-[-20px] p-5 m-5 rounded-[8px]"
                    @click="popUpModalElem._fn.show()">

                    <span class="text-[16px]">Yes, financial institution</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967Z"
                            fill="#3C70FF" />
                    </svg>
                </div>
                <div class="flex items-center justify-between bg-[#f5f5f7]  mt-[-20px] p-5 m-5 rounded-[8px]"
                    @click="popUpModalElem._fn.show()">

                    <span class="text-[16px]">No, I’m not</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967Z"
                            fill="#3C70FF" />
                    </svg>
                </div>

            </div>

        </div>



        <popupModal v-if="pageIsReady" ref="popUpModalElem">
            <template v-slot:title>
                Please provide details

            </template>
            <template v-slot:body>
                <div class=" flex flex-col gap-[16px]">
                    <inputForm :label="'Full name'"></inputForm>
                    <div>
                        <label class="text-[12px] pb-[10px] relative block">Relationship</label>
                        <select class="w-full border-[var(--blue)] border-[2px] text-[14px]  p-2.5 rounded-[8px]">
                            <option default disabled selected>Select an option</option>
                            <option>Father</option>
                            <option>Mother</option>
                            <option>Sibling</option>
                            <option>Child</option>
                            <option>Father-in-law</option>
                            <option>Mother-in-law</option>
                        </select>
                    </div>
                    <inputForm :label="'Exchange name'"></inputForm>
                    <inputForm :label="'Role/position'"></inputForm>
                    <router-link :to="{ path: '/PersonalDetails_Provide' }" v-slot="{navigate }">

                        <btnForm @click="navigate" class="small-pulse">Continue</btnForm>
                    </router-link>

                </div>

            </template>
        </popupModal>


    </PhoneContainer>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from "vue";
import inputForm from "@/components/form/input-form.vue";
import btnForm from "@/components/form/btn-form.vue";

// import jeboy_slidingModal from "@/components/mini_components/jeboy_slidingModal.vue";
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import PhoneContainer from "@/components/container/PhoneContainer.vue";
import popupModal from '@/components/mini_components/popupModal.vue';
const showModal = ref(false)

const pageIsReady = ref(false)
const popUpModalElem = ref(null)

const height = ref(window.innerHeight)
const width = ref(window.innerWidth)


const form = reactive({
    fullname: null,
    relationship: null,
    exchange_name: null,
    role: null
})
const bg = ref('#cccbd1')

const changeBG = () => {
    if (form.role) {
        bg.value = "#3c70ff"
    }
}


onMounted(() => {
    pageIsReady.value = true
})
</script>

<style scoped>
.label-transparent {
    display: none;
}
</style>
