<template>

    <teleport to="#phoneFrame">
        <Transition name="modalAnimation">

            <!-- <div v-if="props.show">
                hahah testing only
            </div> -->
            <CompanyAddress v-if="props.show == true && props.type === 'address' && modalType.companyAddress == true"
                @next:page="modalType.country = true, modalType.companyAddress = false" @modal:close="reset()"
                @select:companyAddress="handleCompanyAddress">
                <template #header>
                    Company address
                </template>
            </CompanyAddress>


            <CountryList v-else-if="props.show == true && props.type === 'address' && modalType.country === true"
                @modal:close="reset()" @select:country="handleSelectCountry">
                <template #header>
                    Select country
                </template>
            </CountryList>

            <CityList :forRegions="props.forRegions"
                v-else-if="props.show && props.type === 'address' && modalType.city === true" @modal:close="reset()"
                @select:city="handleSelectCity" :code="code">
                <template #header>
                    Select city
                </template>
            </CityList>

            <InputAddress v-else-if="props.show && props.type === 'address' && modalType.addressLine === true"
                @modal:close="reset()" @input:address="handleInputedAdress">
                <template #header>
                    Provide address line
                </template>
            </InputAddress>

            <IndustryList v-else-if="props.show && props.type === 'industry'" @modal:close="reset()"
                :forIndustry="props.occupations" @select:industry="handleSelectedIndustry">
                <template #header>
                    Select industry
                </template>
            </IndustryList>

            <TitlesList v-else-if="props.show && props.type === 'titles'" @modal:close="reset()"
                :forTitles="props.titles" @select:title="handleSelectedTitle">
                <template #header>
                    Select title
                </template>
            </TitlesList>
        </Transition>
    </teleport>
    <teleport to="#phoneFrame">
        <Transition name="modalAnimationBD">
            <div v-if="props.show" @click="reset()"
                class="absolute bg-[rgba(0,0,0,.8)] w-full h-full z-[200] top-[0px] left-[0px]">
            </div>
        </Transition>
    </teleport>

</template>

<script setup>
import { defineProps, defineEmits, reactive, ref, watch } from 'vue';
import btnForm from "@/components/form/btn-form.vue";
import CompanyAddress from './CompanyAddress.vue';
import CountryList from './CountryList.vue';
import CityList from './CityList.vue';
import InputAddress from './InputAddress.vue';
import IndustryList from './IndustryList.vue';
import TitlesList from './TitlesList.vue';

const props = defineProps({
    show: {
        default: false
    },
    forRegions: {
        default: null
    },
    type: {
        default: ''
    },
    occupations: {
        default: []
    },
    titles: {
        default: []
    }
})

watch(() => props.occupations, (change) => {
    console.log("/////////////: ", change)
})

const companyAddress = ref(null);
const code = ref('');

const modalType = reactive({
    companyAddress: true,
    country: false,
    city: false,
    addressLine: false
})

const compileLoc = reactive({
    country: null,
    city: null,
    addressLine: null
})

const reset = () => {
    modalType.companyAddress = true, modalType.country = false, modalType.city = false, modalType.addressLine = false
    emit('modal:close')
}

const handleCompanyAddress = (data) => {
    emit('sected:address', `${data.name}, ${data.location}`);
    emit('modal:close');
}

const handleSelectCountry = (data) => {
    // console.log("====================>>>>", data.country)
    compileLoc.country = data.country;
    modalType.country = false, modalType.companyAddress = false, modalType.city = true;
    code.value = data.code;
    emit('country:code', data.code);
}

const handleSelectCity = (data) => {
    compileLoc.city = data;
    modalType.city = false, modalType.addressLine = true;
}

const handleInputedAdress = (data) => {
    compileLoc.addressLine = data;
    modalType.addressLine = false;
    emit('sected:address', `${compileLoc.addressLine}, ${compileLoc.city}, ${compileLoc.country}`);
    reset();
}

const handleSelectedIndustry = (data) => {
    emit('select:industry', data);
    reset();
}

const handleSelectedTitle = (data) => {
    emit('select:title', data);
    reset();
}



const emit = defineEmits(['modal:close', 'select:country', 'sected:address', 'country:code'])

</script>

<style scoped>
.modalAnimation-enter-active {
    animation: modalAnimation-in 0.5s linear;
}

.modalAnimation-leave-active {
    animation: modalAnimation-in 0.5s reverse forwards linear;
}

@keyframes modalAnimation-in {
    0% {
        transform: translateY(100%) translateZ(0);
        pointer-events: none;
    }

    100% {
        transform: translateY(0%) translateZ(0);
        pointer-events: all;
    }
}

.modalAnimationBD-enter-active {
    animation: modalAnimationBD-in 0.5s linear;
}

.modalAnimationBD-leave-active {
    animation: modalAnimationBD-in 0.5s reverse forwards linear;
}

@keyframes modalAnimationBD-in {
    0% {
        opacity: 0;
        pointer-events: none;
    }

    99% {
        pointer-events: none;
    }

    100% {
        opacity: 1;
        pointer-events: all;
    }
}
</style>