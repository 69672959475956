<template lang="">
    <PhoneContainer>
      <MobileNavbar />
      <BackNav></BackNav>

      <div class="ml-[12px] mr-[12px] mt-[80px]">
        <h2 class="text-[24px] font-bold">Proof of address</h2>
        <p class="text-[14px] text-gray-400 mr-[12px]">The document should match the provided address. You can upload a utility bill, bank statement, rental agreement or title deed.</p>
       
      

    <div class=" p-[20px] bg-gray-200 mt-[16px]">
        <div class="flex justify-between w-full mb-[15px] ">
            <div class="flex text-[16px] font-bold">
                Document 
            </div>
            <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.25 2.75H9C6.57397 2.75 5.07086 3.2398 4.15533 4.15533C3.2398 5.07086 2.75 6.57397 2.75 9V15C2.75 17.426 3.2398 18.9291 4.15533 19.8447C5.07086 20.7602 6.57397 21.25 9 21.25H15C17.426 21.25 18.9291 20.7602 19.8447 19.8447C20.7602 18.9291 21.25 17.426 21.25 15V10.75H18C16.4585 10.75 15.1921 10.5028 14.3447 9.65533C13.4972 8.80791 13.25 7.54155 13.25 6V2.75ZM22.5303 9.46967C22.5437 9.48308 22.5566 9.4969 22.5688 9.51112C22.6801 9.6405 22.7421 9.80184 22.7493 9.96741C22.7499 9.98018 22.7501 9.99297 22.75 10.0058M22.75 10.0058V15C22.75 17.574 22.2398 19.5709 20.9053 20.9053C19.5709 22.2398 17.574 22.75 15 22.75H9C6.42603 22.75 4.42914 22.2398 3.09467 20.9053C1.7602 19.5709 1.25 17.574 1.25 15V9C1.25 6.42603 1.7602 4.42914 3.09467 3.09467C4.42914 1.7602 6.42603 1.25 9 1.25H13.9941H14C14.0115 1.25 14.023 1.25026 14.0343 1.25077M14.5307 1.47004L22.5303 9.46967L14.5307 1.47004ZM14.75 6V3.81066L20.1893 9.25H18C16.5415 9.25 15.8079 8.99725 15.4053 8.59467C15.0028 8.19209 14.75 7.45845 14.75 6ZM9.09643 10.2562C9.23799 10.2744 9.37533 10.333 9.48985 10.432C9.50526 10.4453 9.52009 10.4592 9.53431 10.4736L11.5303 12.4697C11.8232 12.7626 11.8232 13.2374 11.5303 13.5303C11.2374 13.8232 10.7626 13.8232 10.4697 13.5303L9.75 12.8107V17C9.75 17.4142 9.41421 17.75 9 17.75C8.58579 17.75 8.25 17.4142 8.25 17V12.8107L7.53033 13.5303C7.23744 13.8232 6.76256 13.8232 6.46967 13.5303C6.17678 13.2374 6.17678 12.7626 6.46967 12.4697L8.46967 10.4697C8.60323 10.3361 8.77463 10.2635 8.94936 10.2517C8.99816 10.2484 9.04743 10.2498 9.09643 10.2562Z" fill="#3C70FF"/>
              </svg>
            </div>
        </div>
        <div>
          <img :src="document" class="w-full">
        </div>
    </div>

    <div class="mt-[16px] flex items-center gap-[16px]">
  <input
    class="h-[16px] w-[16px] outline-blue-500"
    type="checkbox"
    v-model="obj.provideDoc"
  />
  <span class="text-[14px]">
    Provided document is not in my name
  </span>
</div>

<div v-if="obj.provideDoc" class="bg-gray-100 p-[15px] flex flex-row gap-[12px] mt-[16px] text-[14px]">
  <div>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.916504 10.0001C0.916504 5.00253 5.00229 0.916748 9.99984 0.916748C14.9974 0.916748 19.0832 5.00253 19.0832 10.0001C19.0832 14.9976 14.9974 19.0834 9.99984 19.0834C5.00229 19.0834 0.916504 14.9976 0.916504 10.0001ZM9.99984 2.41675C5.83072 2.41675 2.4165 5.83096 2.4165 10.0001C2.4165 14.1692 5.83072 17.5834 9.99984 17.5834C14.169 17.5834 17.5832 14.1692 17.5832 10.0001C17.5832 5.83096 14.169 2.41675 9.99984 2.41675ZM9.99984 8.41675C10.4141 8.41675 10.7498 8.75253 10.7498 9.16675V13.3334C10.7498 13.7476 10.4141 14.0834 9.99984 14.0834C9.58562 14.0834 9.24984 13.7476 9.24984 13.3334V9.16675C9.24984 8.75253 9.58562 8.41675 9.99984 8.41675ZM9.99526 5.91675C9.58105 5.91675 9.24526 6.25253 9.24526 6.66675C9.24526 7.08096 9.58105 7.41675 9.99526 7.41675H10.0027C10.417 7.41675 10.7527 7.08096 10.7527 6.66675C10.7527 6.25253 10.417 5.91675 10.0027 5.91675H9.99526Z" fill="#3C70FF"/>
</svg>

  </div>
  <div>
    Your account will be under review. We may reach out to you for additional information or documents.
  </div>
</div>

<div class="mt-[16px] pb-[16px]">
  
  <btnForm :btnDisabled="disabledBtn" @click="router.push({name:'term2'})">Submit document</btnForm>
</div>
  
  </div>
    </PhoneContainer>
  </template>

<script setup>
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import PhoneContainer from "@/components/container/PhoneContainer.vue";
import btnForm from "@/components/form/btn-form.vue";
import BackNav from "@/components/mini_components/BackNav.vue";
import document from '@/assets/document.png';
import { ref, watch, reactive } from "vue"
import { useRouter } from 'vue-router';
const router = useRouter()


const disabledBtn = ref(true);

const obj = reactive({ provideDoc: false });

watch(
  () => obj.provideDoc,
  (change) => {
    console.log("doms the great: ", change);
    if(change == true){
      disabledBtn.value = false
    } else{
      disabledBtn.value = true
    }
    
  }
);


</script>