<template>
  <div class="w-full flex gap-[12px]">
    <input
      v-for="(digit, index) in pinCode"
      :key="index"
      type="number"
      class="bg-[#F5F5F7] w-full h-[64px] text-[32px] rounded-lg focus:outline-[var(--blue)] focus:ring-[var(--blue)] text-center"
      v-model="pinCode[index]"
      maxlength="1"
      @input="handleInput(index)"
      @keydown="handleKeydown($event, index)"
      ref="inputs"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

// Define props to receive pinCode and emit event when done
const props = defineProps({
  initialPinCode: {
    type: Array,
    required: true,
    default: () => Array(6).fill(""),
  },
});

// Emits for the done event
const emits = defineEmits(["donePincode"]);

const pinCode = ref([...props.initialPinCode]); // Make pinCode reactive
const inputs = ref([]);

const handleInput = (index) => {
  // Ensure only a single character
  pinCode.value[index] = pinCode.value[index].toString().slice(0, 1);

  // Move focus to next input
  if (pinCode.value[index] && index < pinCode.value.length - 1) {
    inputs.value[index + 1].focus();
  }

  // Emit event if all are filled
  if (pinCode.value.every((digit) => digit !== "")) {
    emits("donePincode", pinCode.value);
  }
};

const handleKeydown = (event, index) => {
  if (event.key === "Backspace" && index > 0 && !pinCode.value[index]) {
    inputs.value[index - 1].focus();
  }
};

onMounted(() => {
  inputs.value = document.querySelectorAll("input");

  // Emit the event if the initial pinCode is fully filled
  if (pinCode.value.every((digit) => digit !== "")) {
    emits("donePincode", pinCode.value);
  }
});

// Sync prop updates with pinCode ref
watch(() => props.initialPinCode, (newVal) => {
  pinCode.value = [...newVal];
  
  // Emit the event if the updated pinCode is fully filled
  if (pinCode.value.every((digit) => digit !== "")) {
    setTimeout(() => {
      emits("donePincode", pinCode.value);
    }, 2000);
   
  }
});
</script>


<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
