<template>
    <phoneContainer >
        <MobileNavbar></MobileNavbar>
        <div class="flex w-full h-full flex-col relative p-[24px_16px]">
            <div class="flex w-full flex-col grow-[1] justify-center items-center">
                <img class="w-[117px] h-[117px]" src="./../assets/icons/limitedaccess.png"/>

                <div class="flex flex-col w-full gap-[12px]">
                    <div class="text-[24px] text-center font-bolder">Limited access!</div>
                    <div class="text-[14px] text-center">Verify your identity within 7 days to unlock all features and enjoy a seamless experience.</div>
                </div>

            </div>
            <div class="flex flex-col gap-[12px] w-full">
                    <btnForm class="small-pulse" @click="router.push({name: 'enterphonenumber'})">Verify account</btnForm>
                    <btnForm class="!bg-transparent !text-[var(--blue)] !border-transparent" >Skip for now</btnForm>
            </div>
        </div>

    </phoneContainer>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import phoneContainer from '@/components/container/PhoneContainer.vue';
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import { useRouter } from 'vue-router';
import btnForm from "@/components/form/btn-form.vue";
import Modal from "./../components/mini_components/ModalContent.vue"
const router = useRouter()
</script>