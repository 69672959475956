<template>
  <div class="w-full p-[16px] flex h-[100vh] justify-center items-center z-2 relative" v-if="!isStart" >
      <btnForm class="h-[50px] max-w-[200px]" @click="openFullscreen()">Start Simulation</btnForm>
  </div>
  <PhoneContainer v-if="isStart">
    <MobileNavbar :textColor="'white'"></MobileNavbar>
    <div class="video-wrapper">
      <video
        
        class="video-responsive"
        :src="videoSrc"
        autoplay
        muted
        playsinline
        @ended="onVideoEnd"
      ></video>
    </div>

  </PhoneContainer>


</template>

<script setup>
import { onMounted,ref } from "vue";
import PhoneContainer from "./container/PhoneContainer.vue";
import MobileNavbar from "./mini_components/MobileNavbar.vue";
import { useRouter } from "vue-router";
import btnForm from "./form/btn-form.vue";

const router = useRouter();
// Dynamically set the video source
const videoSrc = new URL("../assets/loading_video.mp4", import.meta.url).href;
const isStart = ref(false)
const onVideoEnd = () => {
  setTimeout(() => {
    router.push({ name: "signuporsigin" });
  }, 300);
};
const openFullscreen = ()=> {
    isStart.value = true
    let element = document.body
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { element.mozRequestFullScreen(); } else if (element.webkitRequestFullscreen) { element.webkitRequestFullscreen(); } else if (element.msRequestFullscreen) { element.msRequestFullscreen(); }
}
onMounted(()=>{
  
})
</script>

<style scoped>
/* Ensure no scrolling on the entire page for mobile */
:global(html, body) {
  margin: 0;
  padding: 0;
}

/* Video Wrapper Styles */
.video-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Video Responsive Defaults */
.video-responsive {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

/* Mobile Specific Styles */
@media (max-width: 768px) {
  :global(html, body) {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .video-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .video-responsive {
    width: 100%;
    height: 100%;
  }
}
</style>
