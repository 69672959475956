<template>
<div class="group w-full">
    <label v-if="$Props.label" class="block mb-2 text-[12px] font-medium text-[var(--label)]">{{ $Props.label }}</label>
    <input :type="$Props.type" class="border text-[16px] border-[var(--outline)] text-[var(--black)] text-sm rounded-lg focus:outline-[var(--blue)] focus:ring-[var(--blue)] block w-full p-2.5" :placeholder="$Props.placeholder" :value="$Props.inputModel" @input="_fn.change($event)"/>
</div>
</template>
<style scoped>
.group:focus-within label { 
    color: var(--blue); 
}
</style>
<script setup>
import {defineProps} from 'vue'
const $Props = defineProps({
    "inputModel":{default:''},
    "label":{required:false},
    "placeholder":{default:''},
    "type":{default:'text'}
})

const $emits = defineEmits(["inputModel:update"])

const _fn = {
    "change":(evt)=>{
        $emits("inputModel:update",evt.target.value)
    }
}
</script>