<template>
<PhoneContainer>
    <MobileNavbar :text-color="'white'"></MobileNavbar>
    <div class="bg-[var(--blue)] absolute w-full h-full px-[20px]">
        <div class="mt-[44px] flex justify-end">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7 11.75C13.69 11.75 11.25 14.19 11.25 17.2V23.8C11.25 26.6584 13.4506 29.0029 16.25 29.2317V30.6315C16.25 32.0292 17.8078 32.8629 18.9707 32.0876L23.2271 29.25H27.3C30.31 29.25 32.75 26.81 32.75 23.8V17.2C32.75 14.19 30.3099 11.75 27.3 11.75H16.7ZM12.75 17.2C12.75 15.0185 14.5185 13.25 16.7 13.25H27.3C29.4815 13.25 31.25 15.0185 31.25 17.2V23.8C31.25 25.9815 29.4815 27.75 27.3 27.75H23C22.8519 27.75 22.7072 27.7938 22.584 27.876L18.1387 30.8395C17.9725 30.9503 17.75 30.8312 17.75 30.6315V28.5C17.75 28.0858 17.4142 27.75 17 27.75H16.7C14.5185 27.75 12.75 25.9815 12.75 23.8V17.2ZM19.59 18.52C19.59 17.1858 20.6658 16.11 22 16.11C23.3342 16.11 24.41 17.1858 24.41 18.52C24.41 19.5729 23.7177 20.1286 23.2647 20.4382C23.0703 20.5725 22.945 20.6782 22.8638 20.7871C22.7963 20.8775 22.75 20.9821 22.75 21.15V21.36C22.75 21.7742 22.4142 22.11 22 22.11C21.5858 22.11 21.25 21.7742 21.25 21.36V21.15C21.25 20.0732 21.9587 19.5171 22.4139 19.2028L22.4171 19.2006L22.4171 19.2007C22.6022 19.0742 22.7234 18.971 22.8011 18.866C22.8662 18.7782 22.91 18.6777 22.91 18.52C22.91 18.0142 22.5058 17.61 22 17.61C21.4942 17.61 21.09 18.0142 21.09 18.52C21.09 18.9342 20.7542 19.27 20.34 19.27C19.9258 19.27 19.59 18.9342 19.59 18.52ZM21.2455 23.75C21.2455 23.3358 21.5813 23 21.9955 23H22.0045C22.4187 23 22.7545 23.3358 22.7545 23.75C22.7545 24.1642 22.4187 24.5 22.0045 24.5H21.9955C21.5813 24.5 21.2455 24.1642 21.2455 23.75Z" fill="white"/>
                </svg>   
        </div>
        <div class="">
            <p class="method_title">
                Verify your identity
            </p>
            <p class="methods_subtitle mt-[15px]">We're excited to have you on board! Let's get you verified so you can start exploring all that xCube has to offer. This will only take few minutes</p>
        </div>

        <div class="mt-[15px]">
            <div v-for="list in method_list" class="flex gap-1 items-centers mb-[15px]">
                <div class="bg-white h-[24px] w-[24px] rounded-full flex justify-center items-center">
                    <p class=" text-[var(--blue)]">{{list.num}}</p>
            
                </div>
                <p class="methodlisttxt text-white">{{list.desc}}</p>
            </div>
        </div>
        <div class="grid gap-[10px] absolute bottom-[30px] w-full left-0 px-[20px]  ">
        <div class="flex gap-1 items-center small-pulse " @click="checkedterms = ! checkedterms">
            <input id="link-checkbox" type="checkbox" :checked="checkedterms" class="custom-checkbox"></input>
            <p class="methods_terms">I have read and agree to the <span class="underline">Terms & Conditions</span></p>
         
        </div>
        <div class="grid gap-[12px]">
            <btnForm :disabled="!checkedterms" theme="3" class="small-pulse" @click="proceedRoute('scanid')"><span class="text-black flex gap-[4px] "><img src="../assets/icons/icon.png" class="w-[21.5px] h-[21px]">Continue with Emirates ID</span></btnForm>
            <btnForm :disabled="true" theme="3"><span class="text-black gap-[4px] flex"><img src="../assets/icons/UAE Pass.png" class="w-[21.5px] h-[21px]">Continue with UAE PASS </span></btnForm>
            <btnForm class="small-pulse" @click="router.push({name:'Scan_passport_ins'})">Continue with Passport </btnForm>
        </div>
    </div>
    </div>
</PhoneContainer>
</template>
<script setup>
    import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
    import PhoneContainer from "@/components/container/PhoneContainer.vue"; 
    import btnForm from "@/components/form/btn-form.vue";
    import {ref} from 'vue'
    import { useRouter } from "vue-router";
    const router = useRouter()
    const method_list = ref([
        {
            num:1,
            desc:'Scan your ID'
        },
        {
            num:3,
            desc:'Complete photo verification'
        },
        {
            num:4,
            desc:'Verify your address'
        },
        {
            num:5,
            desc:'Answer trading questions'
        },
    ])
    const proceedRoute = (value) =>{
        router.push('/' + value)
    } 
    const checkedterms = ref(false)
</script>

<style scoped>


</style>
