<template>

    <div class="w-full absolute px-[16px] top-[48px] text-[#3C70FF] flex flex-row gap-[100px]">
        <div class="relative w-full">
            <svg @click="router.push({ name: props.routeTo })" xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" fill="none"
                viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path d="M5 12H19M5 12L11 6M5 12L11 18" />
            </svg>
            <div class="text-black absolute top-0 left-[50%] translate-x-[-50%] text-[16px] font-bold">
                Personal details
            </div>
        </div>
    </div>

</template>

<script setup>
import { ref, defineProps } from "vue"
import { useRoute, useRouter } from 'vue-router';
const router = useRouter()

const props = defineProps({
    routeTo: {
        default: '/'
    }
})

</script>