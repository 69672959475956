<template>
    <phoneContainer>
        <MobileNavbar></MobileNavbar>
        <div class="bg-white absolute mt-10 !right-[10px] left-[125px] ">
            <img :src="uqudologo" alt="Logo" class="">
        </div>
        <BackNav2></BackNav2>

        <div class="flex justify-center items-center mt-24">
            <img :src="scanid" alt="Logo" class="">
        </div>

        <div class="flex flex-col justify-center items-center text-center">
            <h2 class="text-[24px] font-bold mb-2">
                Scan Emirates ID
            </h2>
            <p class="text-[12px]">
                Fit emirates ID to the frame
            </p>
        </div>

        <div class="flex justify-center items-center text-center mt-[120px]">
            <button @click="proceedscanidcamera()" class="flex items-center text-[24px] font-bold small-pulse">
                Start
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                    stroke="currentColor" class="ml-2 w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
            </button>
        </div>


    </phoneContainer>
</template>

<script setup>
import phoneContainer from '@/components/container/PhoneContainer.vue';
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav2 from '@/components/mini_components/BackNav2.vue';
import uqudologo from '@/assets/uqudologo.png';
import scanid from '@/assets/scanid.png';

import { useRouter } from 'vue-router';


const router = useRouter()
const proceedscanidcamera = () =>{
    router.push({name:'scanidcamera'})
}
</script>
