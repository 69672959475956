import { createRouter, createWebHashHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'
import SignupOrSignin from "../views/SignupOrSignin.vue";
import LoadingView from "../views/LoadingView.vue";
import TermonViews from "@/views/TermonViews.vue";
import DashboardView from "@/views/DashboardView.vue";
import CodeVerification from "@/views/CodeVerification.vue";
import MethodsView from "@/views/MethodsView.vue";
import EnterPhoneNumber from "@/views/EnterPhoneNumber.vue";
import UqudoKyc_nfc_supported from "@/views/UqudoKyc_nfc_supported.vue";
import UqudoKyc_nfc_Notsupported from "@/views/UqudoKyc_nfc_Notsupported.vue";
import ScanIdView from "@/views/ScanIdView.vue";
import ScanIdCameraView from "@/views/ScanIdCameraView.vue";
import Verify_Indentity from "@/views/Verify_Indentity.vue";
import Verify_Indentity2 from "@/views/Verify_Indentity2.vue";
import Verify_Indentity_tryAgain1 from "@/views/Verify_Indentity_tryAgain1.vue";
import Verify_Indentity_tryAgain2 from "@/views/Verify_Indentity_tryAgain2.vue";
import Verify_Indentity_failed from "@/views/Verify_Indentity_failed.vue";
import lets_try_again from "@/views/lets_try_again.vue";
import ReviewConfirm from "@/views/ReviewConfirm.vue";
import verify_offline from "@/views/verify_offline.vue"
import Verify_Indentity_confirmation from "@/views/Verify_Indentity_confirmation.vue"
import scan_emirates from "@/views/scan_emirates.vue"
import ConfirmID from "@/views/ConfirmID.vue";
import AddressDetailsView from "@/views/AddressDetailsView.vue";
import AddressDetails2View from "@/views/AddressDetails2View.vue";
import AddressDetails3View from "@/views/AddressDetails3View.vue";
import ChooseOnMapView from "@/views/ChooseOnMapView.vue";
import ChooseOnMap2View from "@/views/ChooseOnMap2View.vue";
import AllowLocationView from "@/views/AllowLocationView.vue";
import AllowLocation2View from "@/views/AllowLocation2View.vue";
import ProofOfAddress from "@/views/ProofOfAddressView.vue";
import ProofOfAddress2 from "@/views/ProofOfAddress2View.vue";
import LimitedAccess from "@/views/LimitedAccess.vue";
import sourceofIncome from "@/views/sourceofIncome.vue";
import anualincome from "@/views/anualincome.vue";
import boardMember from "@/views/boardMember.vue";
import proofOfEmployment from "@/views/proofOfEmployment.vue";
import proofOfEmployment2 from "@/views/proofOfEmployment2.vue";
import InvestmentExperience from "@/views/InvestmentExperience.vue"
import InvestmentExperience2 from "@/views/InvestmentExperience2.vue"
import InvestmentExperience3 from "@/views/InvestmentExperience3.vue"
import InvestmentExperience4 from "@/views/InvestmentExperience4.vue"
import InvestmentExperience5 from "@/views/InvestmentExperience5.vue"
import InvestmentExperience6 from "@/views/InvestmentExperience6.vue"
import InvestmentExperience7 from "@/views/InvestmentExperience7.vue"
import taxInfo from "@/views/taxInfo.vue"
import TaxInfo2 from "@/views/taxInfo2.vue"
import taxInfo3 from "@/views/taxInfo3.vue"
import CompleteSetup from "@/views/CompleteSetup.vue";
import DashboardView2 from "@/views/DashboardView2.vue";
import PersonalDetails_BoardMember from "@/views/PersonalDetails_BoardMember.vue";
import PersonalDetails_Provide from "@/views/PersonalDetails_Provide.vue";
import ScanPassport from "@/views/ScanPassport.vue";
import Scan_passport_ins from "@/views/Scan_passport_ins.vue";



import TermonViews2 from "@/views/TermonViews2.vue";
import EmploymentStatusView from "@/views/EmploymentStatusView.vue";
import EmploymentDetailsView from "@/views/EmploymentDetailsView.vue";
import TermonAllowViews from "@/views/TermonAllowViews.vue";






const routes = [
  {
    path: "/",
    name: "home",
    component: LoadingView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/SignupView.vue"),
  },
  {
    path: "/loading",
    name: "loading",
    component: LoadingView,
  },
  {
    path: "/signuporsigin",
    name: "signuporsigin",
    component: SignupOrSignin,
  },
  {
    path: "/term",
    name: "term",
    component: TermonViews,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/dashboard2",
    name: "dashboard2",
    component: DashboardView2,
  },

  {
    path: "/codeverification",
    name: "codeverification",
    component: CodeVerification,
  },
  {
    path: "/methods",
    name: "methods",
    component: MethodsView,
  },
  {
    path: "/enterphonenumber",
    name: "enterphonenumber",
    component: EnterPhoneNumber,
  },
  {
    path: "/UqudoKyc_nfc",
    name: "UqudoKyc_nfc",
    component: UqudoKyc_nfc_supported,
  },
  {
    path: "/UqudoKyc_nfc_notSupported",
    name: "UqudoKyc_nfc_notSupported",
    component: UqudoKyc_nfc_Notsupported,
  },
  {
    path: "/scanid",
    name: "scanid",
    component: ScanIdView,
  },
  {
    path: "/scanidcamera",
    name: "scanidcamera",
    component: ScanIdCameraView,
  },
  {
    path: "/Verify_Indentity",
    name: "Verify_Indentity",
    component: Verify_Indentity,
  },
   {
    path: "/Verify_Indentity2",
    name: "Verify_Indentity2",
    component: Verify_Indentity2,
  },
  {
    path: "/Verify_Indentity_tryAgain1",
    name: "Verify_Indentity_tryAgain1",
    component: Verify_Indentity_tryAgain1,
  },
  {
    path: "/Verify_Indentity_tryAgain2",
    name: "Verify_Indentity_tryAgain2",
    component: Verify_Indentity_tryAgain2,
  },
  {
    path: "/Verify_Indentity_failed",
    name: "Verify_Indentity_failed",
    component: Verify_Indentity_failed,
  },
  {
    path: "/lets_try_again",
    name: "lets_try_again",
    component: lets_try_again,
  },
  {
    path: "/reviewconfirm",
    name: "reviewconfirm",
    component: ReviewConfirm,
  },
  {
    path: "/verify_offline",
    name: "verify_offline",
    component: verify_offline,
  },
  {
    path: "/Verify_Indentity_confirmation",
    name: "Verify_Indentity_confirmation",
    component: Verify_Indentity_confirmation,
  },
  {
    path: "/scan_emirates",
    name: "scan_emirates",
    component: scan_emirates,
  },

  {
    path: "/addressdetails",
    name: "addressdetails",
    component: AddressDetailsView,
  },
  {
    path: "/addressdetails2",
    name: "addressdetails2",
    component: AddressDetails2View,
  },
  {
    path: "/addressdetails3",
    name: "addressdetails3",
    component: AddressDetails3View,
  },
  {
    path: "/chooseonmap",
    name: "chooseonmap",
    component: ChooseOnMapView,
  },
  {
    path: "/chooseonmap2",
    name: "chooseonmap2",
    component: ChooseOnMap2View,
  },
  {
    path: "/allowlocation",
    name: "allowlocation",
    component: AllowLocationView,
  },
  {
    path: "/allowlocation2",
    name: "allowlocation2",
    component: AllowLocation2View,
  },
  {
    path: "/proofofaddress",
    name: "proofofaddress",
    component: ProofOfAddress,
  },
  {
    path: "/proofofaddress2",
    name: "proofofaddress2",
    component: ProofOfAddress2,
  },
  {
    path: "/confirmid",
    name: "confirmid",
    component: ConfirmID,
  },
  {
    path: "/limitedaccess",
    name: "limitedaccess",
    component: LimitedAccess
  },
  {
    path: "/sourceofIncome",
    name: "sourceofIncome",
    component: sourceofIncome
  },
  {
    path: "/anualincome",
    name: "anualincome",
    component: anualincome
  },
  {
    path: "/boardMember",
    name: "boardMember",
    component: boardMember
  },
  {
    path: "/proofofEmployment",
    name: "proofofEmployment",
    component: proofOfEmployment
  },
  {
    path: "/proofofEmployment2",
    name: "proofofEmployment2",
    component: proofOfEmployment2
  },
  {
    path: "/investmentexperience",
    name: "investmentexperience",
    component: InvestmentExperience   

  },
  {
    path: "/investmentexperience2",
    name: "investmentexperience2",
    component: InvestmentExperience2  
  },
  {
    path: "/investmentexperience3",
    name: "investmentexperience3",
    component: InvestmentExperience3  
  },
  {
    path: "/investmentexperience4",
    name: "investmentexperience4",
    component: InvestmentExperience4 
  },
  {
    path: "/investmentexperience5",
    name: "investmentexperience5",
    component: InvestmentExperience5 
  },
  {
    path: "/investmentexperience6",
    name: "investmentexperience6",
    component: InvestmentExperience6 
  },
  {
    path: "/investmentexperience7",
    name: "investmentexperience7",
    component: InvestmentExperience7 
  },
  {
    path: "/complete",
    name: "complete",
    component: CompleteSetup 
  },

  {
    path: "/taxinfo",
    name: "taxinfo",
    component: taxInfo 
  },
  {
    path: "/taxinfo2",
    name: "taxinfo2",
    component: TaxInfo2
  },
  {
    path: "/taxinfo3",
    name: "taxinfo3",
    component: taxInfo3
  },
   {
    path: "/PersonalDetails_BoardMember",
    name: "PersonalDetails_BoardMember",
    component: PersonalDetails_BoardMember
  },
  {
    path: "/term2",
    name: "term2",
    component: TermonViews2,
  },
  {
    path: "/employmentstatus",
    name: "employmentstatus",
    component: EmploymentStatusView,
  },
  {
    path: "/employmentdetails",
    name: "employmentdetails",
    component: EmploymentDetailsView,
  },
  {
    path: "/termonallow",
    name: "termonallow",
    component: TermonAllowViews,
  },
    {
    path: "/PersonalDetails_Provide",
    name: "PersonalDetails_Provide",
    component: PersonalDetails_Provide,
  },
    
     {
    path: "/ScanPassport",
    name: "ScanPassport",
    component: ScanPassport,
  },
     
       
     {
    path: "/Scan_passport_ins",
    name: "Scan_passport_ins",
    component: Scan_passport_ins,
  },

  



];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
