<template>
    <!-- <div class="flex flex-col gap-[24px]">
        <inputForm :label="'Full name'"></inputForm>
        <inputForm :label="'Email address'" type="email"></inputForm>
        <inputForm :label="'Password'" type="password"></inputForm>
        <btnForm>Create an account</btnForm>
    </div> -->

    <phoneContainer>
        <MobileNavbar></MobileNavbar>
        <div class="bg-white h-full flex justify-center items-center p-[16px]  flex-col gap-[16px]">
            <BackNav></BackNav>
            <div class="absolute top-[90px] px-[16px]">
                <div v-if="!forMessage" class="w-full mb-[24px]">
                    <headerForm>
                        <template #title>Enter the code from the email</template>
                        <template #subtitle>We’ve sent a 6-digit code to hamad@gmail.com</template>
                    </headerForm>
                </div>
                <div v-if="forMessage" class="w-full mb-[24px]">
                    <headerForm>
                        <template #title>Enter the code from the message</template>
                        <template #subtitle>We’ve sent a 6-digit code to +971 551234567</template>
                    </headerForm>
                </div>
                <div class="mb-[100px]">
                    <pincodeForm  class="small-pulse" :initialPinCode="userPinCode" @donePincode="proceedtoTerms()"></pincodeForm>
                </div>

                <div class="flex flex-col gap-[34px] justify-center items-center">
                    <span class="text-sm text-[#7C7A82] font-thin">You can request a new code in {{ formattedTime }}</span>
                    <div v-if="!forMessage" class="text-[#3C70FF]">
                        <span>Open mail app</span>
                    </div>
                </div>
            </div>

        </div>
    </phoneContainer>

</template>

<script setup>
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import headerForm from '@/components/form/header-form.vue';
import pincodeForm from '@/components/form/pincode-form.vue';
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter(); // Added router to navigate programmatically
const forMessage = ref(false);
const countdown = ref(58); // start with 58 seconds
const formattedTime = ref(formatTime(countdown.value));


// Reactive array for the user's PIN code
const userPinCode = ref(Array(6).fill(""));

// Handle completion of PIN code
const handlePinCodeComplete = () => {
  console.log("PIN Code Complete, starting timeout...");

  // Wait for 3 seconds before updating the PIN code
  setTimeout(() => {
    userPinCode.value = Array(6)
      .fill("")
      .map(() => Math.floor(Math.random() * 10).toString()); // Random numbers between 0-9
    console.log("New Random PIN Code:", userPinCode.value);
  }, 3000);
};
// Function to format the countdown as "mm:ss"
function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
}

// Update countdown every second
function startCountdown() {
  const interval = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
      formattedTime.value = formatTime(countdown.value);
    } else {
      clearInterval(interval); // stop the countdown when it reaches 0
    }
  }, 1000);

  // Clean up the interval if the component is destroyed
  onUnmounted(() => clearInterval(interval));
}

onMounted(() => {
    if (route.query.id === '1') {
        forMessage.value = true;
    }
    startCountdown()
    handlePinCodeComplete(0)
})

const proceedtoTerms = () => {
    const queryParam = router.currentRoute.value.query.id; // Access the current query parameter

    if (queryParam === '1') {
        // Navigate to the 'terms' route if id = 1
        
        router.push({ name: 'methods' });
        // Navigate to the 'methods' route if id = 1
       
    }else{
        router.push({ name: 'term' });
    }
};

</script>
