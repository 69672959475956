<template>

    <teleport to="#phoneFrame">
        <Transition name="modalAnimation">
            <CountryList v-if="props.show && props.type === 'country'" @modal:close="emit('modal:close')"
                @select:country="handleSelectCountry">
                <template #header>
                    {{ props.header }}
                </template>
            </CountryList>
            <CityList :forRegions="props.forRegions" v-else-if="props.show && props.type === 'city'"
                @modal:close="emit('modal:close')" @select:city="handleSelectCity">
                <template #header>
                    {{ props.cityHeader }}
                </template>
            </CityList>
            <InputAddress v-else-if="props.show && props.type === 'address'" @modal:close="emit('modal:close')"
                @input:address="handleSelectAdress">
                <template #header>
                    {{ props.addressHeader }}
                </template>
            </InputAddress>
        </Transition>
    </teleport>
    <teleport to="#phoneFrame">
        <Transition name="modalAnimationBD">
            <div v-if="props.show" @click="emit('modal:close')"
                class="absolute bg-[rgba(0,0,0,.8)] w-full h-full z-[200] top-[0px] left-[0px]">
            </div>
        </Transition>
    </teleport>

</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import btnForm from "@/components/form/btn-form.vue";
import AppleModalContent from './AppleModalContent.vue';
import GoogleModalContent from './GoogleModalContent.vue';
import CountryList from './CountryList.vue';
import CityList from './CityList.vue';
import InputAddress from './InputAddress.vue';

const props = defineProps({
    show: {
        default: false
    },
    type: {
        default: ''
    },
    forRegions: {
        default: null
    },
    header: {
        default: ''
    },
    cityHeader: {
        default: 'City of Birth'
    },
    addressHeader: {
        default: 'Provide address line'
    }
})

const handleSelectCountry = (data) => {
    let code = data.code;
    let country = data.country;
    // console.log("test only: ", data.code, data.country)
    emit('select:country', { code, country });
    emit('modal:close');
}

const handleSelectCity = (data) => {
    emit('select:city', data);
    emit('modal:close');
}

const handleSelectAdress = (data) => {
    emit('input:address', data);
    emit('modal:close');
}

const emit = defineEmits(['modal:close', 'select:country'])

</script>

<style scoped>
.modalAnimation-enter-active {
    animation: modalAnimation-in 0.5s linear;
}

.modalAnimation-leave-active {
    animation: modalAnimation-in 0.5s reverse forwards linear;
}

@keyframes modalAnimation-in {
    0% {
        transform: translateY(100%) translateZ(0);
        pointer-events: none;
    }

    100% {
        transform: translateY(0%) translateZ(0);
        pointer-events: all;
    }
}

.modalAnimationBD-enter-active {
    animation: modalAnimationBD-in 0.5s linear;
}

.modalAnimationBD-leave-active {
    animation: modalAnimationBD-in 0.5s reverse forwards linear;
}

@keyframes modalAnimationBD-in {
    0% {
        opacity: 0;
        pointer-events: none;
    }

    99% {
        pointer-events: none;
    }

    100% {
        opacity: 1;
        pointer-events: all;
    }
}
</style>