<template>
    <phoneContainer>
        <MobileNavbar></MobileNavbar>
        <div class="bg-white h-full flex items-center p-[16px] flex-col gap-[16px] overflow-y-[auto]">
            <div class="pt-[24px] w-full ">
                <div class="w-[calc(52%+32px)] h-[4px] bg-[var(--blue)] rounded mx-[-16px]"></div>
            </div>
            <div class="relative w-full ">
                <div class="absolute"><BackNav class="relative !top-[0px] !m-[0px] !p-[0px] !pt-[0px] cursor-pointer" @click="router.push({name:'investmentexperience'})"></BackNav></div>
                <div class="w-full text-center font-bolder text-[16px]">Investment Experience</div>
            </div>
            <div class="w-full flex flex-col gap-[12px]">
                <div class="text-[24px] font-bolder">What is the time horizon for your investment?</div>
                <div class="text-[14px] text-[#616068]">This is the time period during which you’ll hold your investments to meet a specific goal</div>
            </div>
            <div class="w-full flex flex-col gap-[12px]">
                <div class="border-transparent border-[2px] active:border-[var(--blue)] w-full flex p-[24px] bg-[#F5F5F7] rounded-[8px] justify-center items-center cursor-pointer" @click="router.push({name:'investmentexperience3'})">
                    <div class="grow-[1] text-[16px]">2 years or less</div>
                    <div><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L7.53033 6.46967C7.82322 6.76256 7.82322 7.23744 7.53033 7.53033L1.53033 13.5303C1.23744 13.8232 0.762563 13.8232 0.46967 13.5303C0.176777 13.2374 0.176777 12.7626 0.46967 12.4697L5.93934 7L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z" fill="#3C70FF"/>
</svg>
</div>
                </div>

                <div class="border-transparent border-[2px] active:border-[var(--blue)] w-full flex p-[24px] bg-[#F5F5F7] rounded-[8px] justify-center items-center cursor-pointer" @click="router.push({name:'investmentexperience3'})">
                    <div class="grow-[1] text-[16px]">Between 2 and 5 years</div>
                    <div><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L7.53033 6.46967C7.82322 6.76256 7.82322 7.23744 7.53033 7.53033L1.53033 13.5303C1.23744 13.8232 0.762563 13.8232 0.46967 13.5303C0.176777 13.2374 0.176777 12.7626 0.46967 12.4697L5.93934 7L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z" fill="#3C70FF"/>
</svg>
</div>
                </div>

                <div class="border-transparent border-[2px] active:border-[var(--blue)] w-full flex p-[24px] bg-[#F5F5F7] rounded-[8px] justify-center items-center cursor-pointer" @click="router.push({name:'investmentexperience3'})">
                    <div class="grow-[1] text-[16px]">More than 5 years</div>
                    <div><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L7.53033 6.46967C7.82322 6.76256 7.82322 7.23744 7.53033 7.53033L1.53033 13.5303C1.23744 13.8232 0.762563 13.8232 0.46967 13.5303C0.176777 13.2374 0.176777 12.7626 0.46967 12.4697L5.93934 7L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z" fill="#3C70FF"/>
</svg>
</div>
                </div>


            </div>
        </div>
    </phoneContainer>
</template>
<script setup>
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import { useRouter } from 'vue-router';
const router = useRouter()

</script>