<template>
  <div class="relative">
    <phoneContainer>
      <MobileNavbar></MobileNavbar>
      <div v-if="currentIncome == 1" class="pt-[24px] w-full mt-[13px]">
        <div
          class="w-[calc(10%+32px)] h-[4px] bg-[var(--blue)] rounded mx-[-16px]"
        ></div>
      </div>
      <div v-else  class="pt-[24px] w-full mt-[13px]">
        <div
          class="w-[calc(20%+32px)] h-[4px] bg-[var(--blue)] rounded mx-[-16px]"
        ></div>
      </div>
      <div class="flex justify-between mt-[16px] px-[13px]">
        
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-[25px] text-blue-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </div>
        <div class="font-bold text-[16px]">Personal details</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div class="px-[13px] py-[10px] top-[8px]">
        <p class="text-[24px] font-bold">{{ instruction[insIndex].title }}</p>
        <p class="text-[14px] mt-[10px]">
          {{ instruction[insIndex].description }}
        </p>
      </div>
      <div class="px-[13px] delay-300 !duration-[5s]" v-if="currentIncome == 1">
        <div id="choice_1" class="transition-all grid gap-2 ease-in-out">
          <div
            v-for="(items1, index) in income1"
            :key="index"
            class="bg-gray-50 py-[20px] px-[20px] rounded-[8px] flex justify-between items-center cursor-pointer"
            :class="
              selectedIncome == items1.id
                ? 'border-[1.5px] border-[var(--blue)] bg-blue-50'
                : 'border-[1.5px]'
            "
            @click="selectAnnualIncome(items1.id)"
          >
            <div>
              <p class="text-[16px] mb-[2px]">{{ items1.range_AED }}</p>
              <p class="text-[14px] text-gray-400">{{ items1.range_USD }}</p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-[24px]"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="px-[13px] delay-300 !duration-[5s]" v-else>
        <div id="choice_2" class="transition-all grid gap-2 ease-in-out">
          <div
            v-for="(items1, index) in income1"
            :key="index"
            class="bg-gray-50 py-[20px] px-[20px] rounded-[8px] flex justify-between items-center cursor-pointer"
            :class="
              selectedIncome == items1.id
             ? 'border-[1.5px] border-[var(--blue)] bg-blue-50'
                : 'border-[1.5px]'
            "
            @click="selectAnnualIncome(items1.id)"
          >
            <div>
              <p class="text-[16px] mb-[2px]">{{ items1.range_AED }}</p>
              <p class="text-[14px] text-gray-400">{{ items1.range_USD }}</p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-[24px]"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </phoneContainer>
  </div>
</template>

<script setup>
import phoneContainer from "@/components/container/PhoneContainer.vue";
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter()
const insIndex = ref(0);
const currentIncome = ref(1);
const income1 = ref([
  { id: 1, range_AED: "Less than 100,000 AED", range_USD: "~ 27,000 USD" },
  {
    id: 2,
    range_AED: "100,000 to 500,000 AED",
    range_USD: "~ 27,000 to 136,000 USD",
  },
  {
    id: 3,
    range_AED: "500,000 to 2,500,000 AED",
    range_USD: "~ 136,000 to 680,000 USD",
  },
  { id: 4, range_AED: "More than 2,500,000 AED", range_USD: "~ 680,000 USD" },
]);
const income2 = ref([
  { id: 1, range_AED: "Less than 250,000 AED", range_USD: "~ 68,000 USD" },
  {
    id: 2,
    range_AED: "250,000 to 500,000 AED",
    range_USD: "~ 68,000 to 136,000 USD",
  },
  {
    id: 3,
    range_AED: "500,000 to 2,000,000 AED",
    range_USD: "~ 136,000 to 544,000 USD",
  },
  { id: 4, range_AED: "More than 2,000,000 AED", range_USD: "~ 544,000 USD" },
]);

const instruction = ref([
  {
    title: "What is your annual income?",
    description:
      "Please select the option that best describes the total income from the sources you chose earlier",
  },
  {
    title: "What is your estimated total net worth?",
    description:
      "Please select the option that best represents the total amount of assets you currently hold, minus your total outstanding liabilities",
  },
]);
const selectedIncome = ref();

const selectAnnualIncome = (value) => {
  selectedIncome.value = value;
  setTimeout(() => {
    if (currentIncome.value == 1) {
      insIndex.value = 1;
      currentIncome.value = 2;
      selectedIncome.value = "";
    }else{
        router.push({name: 'boardMember'})
    }
  }, 200);
};
</script>
