<template>
  <div class="relative">
    <phoneContainer>
      <MobileNavbar></MobileNavbar>

      <div class="backdrop-blur-container" v-if="isVisible"></div>
      <div class="pt-[24px] w-full mt-[13px]">
        <div
          class="w-[calc(5%+32px)] h-[4px] bg-[var(--blue)] rounded mx-[-16px]"
        ></div>
      </div>
      <div class="flex justify-between mt-[16px] px-[13px]">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-[25px] text-blue-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </div>
        <div class="font-bolder text-[16px]">Personal details</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div class="px-[16px] py-[10px] top-[8px]">
        <p class="text-[24px] font-bold">What are your sources of income?</p>
        <p class="text-[14px] mt-[10px]">
          Please select at least one option that continuously generates income.
          Your selections will be considered as the source of funds for your
          investments.<span
            class="text-blue-500 cursor-pointer"
            @click="(isVisible = true), (modalType = 'desc')"
          >
            See definitions</span
          >
        </p>
      </div>
      <div class="mt-[10px] grid gap-[8px] px-[13px] overflow-y-auto">
        <div
        v-for="(item, index) in data"
        :key="item.id"
        :class="
          selectedSources.includes(item.id)
            ? 'border-[1.5px] border-blue-500 bg-blue-50'
            : 'border-[1.5px] border-opacity-0'
        "
        class="bg-gray-100 max-h-[75px] flex justify-between items-center py-[20px] rounded-[14px] px-[15px] cursor-pointer"
        @click="toggleSelection(item.id)"
      >
        <div class="flex flex-col justify-start">
          <p>{{ item.source }}</p>
          <div
            v-if="otherIncome && item.id == 10"
            class="text-[14px] cursor-pointer"
          >
            <span>{{ otherIncome }}</span> - 
            <span @click.stop="isVisible = true" class="text-blue-500">Edit</span>
          </div>
        </div>
      
        <div class="flex items-center space-x-2">
          <input
            :id="'checkbox-' + item.id"
            type="checkbox"
            v-model="selectedSources"
            :value="item.id"
            class="hidden"
          />
          <label
            :for="'checkbox-' + item.id"
            class="blue-custom-checkbox-label"
          ></label>
        </div>
      </div>
      
      </div>
      <div class="w-full flex justify-center p-[16px]">
        <btnForm
          :btn-disabled="selectedSources.length === 0"
          theme="1"
          @click="checkIncome()"
          class="bg-blue-500"
        >
          {{
            selectedSources.length === 0
              ? "Please select at least one option"
              : "Continue"
          }}
        </btnForm>
      </div>
      <transition
        class="bg-white"
        name="slide-fade"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <div
          v-if="isVisible"
          class="absolute z-[2] bg-white bottom-0 w-full h-[90%] border rounded-t-[16px] px-[30px]"
        >
          <div
            class="flex py-[16px] h-[56px] justify-between mb-[2px]"
            @click="closeModal"
          >
            <div>
              <div v-if="modalType === 'others'" class="text-[20px] font-bold">
                Provide other source of income
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-[30px] text-blue-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div
            class="grid gap-[24px] h-[100%] pb-[20%] overflow-y-auto"
            v-if="modalType === 'desc'"
          >
            <div v-for="desc in dataDesc" :key="desc.source">
              <p class="text-[20px] font-bold">{{ desc.source }}</p>
              <div class="font-[16px]">{{ desc.description }}</div>
            </div>
          </div>
          <div v-else class="h-[100%] overflow-y-auto">
            <input
              type="text"
              v-model="otherIncome"
              ref="inputRefOtherIncome"
              id="first_name"
              class="bg-transparent h-[400px] text-black text-[20px] rounded-lg focus:outline-none block w-full p-[10px]"
            />

            <btnForm
              theme="1"
              :btn-disabled="!otherIncome"
              class="mt-[20px]"
              @click="closeModal()"
              >Confirm</btnForm
            >
          </div>
        </div>
      </transition>
    </phoneContainer>
  </div>
</template>

<script setup>
import phoneContainer from "@/components/container/PhoneContainer.vue";
import btnForm from "@/components/form/btn-form.vue";
import MobileNavbar from "@/components/mini_components/MobileNavbar.vue";
import ModalContent from "@/components/mini_components/ModalContent.vue";

import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const isVisible = ref(false);
const modalType = ref("desc");
const otherIncome = ref("");
const inputRefOtherIncome = ref(null);
onMounted(() => {

});
// Close modal function
function closeModal() {
  isVisible.value = false;
}

const data = ref([
  { id: 1, source: "Salary" },
  { id: 2, source: "Savings" },
  { id: 3, source: "Private business" },
  { id: 4, source: "Inheritance" },
  { id: 5, source: "Real estate" },
  { id: 6, source: "Family support" },
  { id: 7, source: "Retirement (pension)" },
  { id: 8, source: "Full-time trader" },
  { id: 9, source: "Financial markets" },
  { id: 10, source: "Other" },
]);

const dataDesc = ref([
  {
    id: 1,
    title: "Income Sources",
    source: "Salary",
    description:
      "Fixed regular payments you receive from your employer expressed as an annual sum",
    value: 0,
  },
  {
    id: 2,
    title: "Income Sources",
    source: "Savings",
    description:
      "Interest payments received on saved money, such as time deposits, interest-bearing savings accounts, etc.",
    value: 0,
  },
  {
    id: 3,
    title: "Income Sources",
    source: "Private business",
    description:
      "Personal income derived from business or company revenues and profits",
    value: 0,
  },
  {
    id: 4,
    title: "Income Sources",
    source: "Inheritance",
    description:
      "Income source from an inheritance legacy that generates income",
    value: 0,
  },
  {
    id: 5,
    title: "Income Sources",
    source: "Real estate",
    description:
      "Income source from real estate property that generates rental income",
    value: 0,
  },
  {
    id: 6,
    title: "Income Sources",
    source: "Family support",
    description: "Financial support provided by a family member",
    value: 0,
  },
  {
    id: 7,
    title: "Income Sources",
    source: "Retirement (pension)",
    description:
      "Fixed regular pension payments received expressed as an annual sum",
    value: 0,
  },
  {
    id: 8,
    title: "Income Sources",
    source: "Full-time trader",
    description:
      "Income derived from trading financial instruments like stocks, bonds, etc., on a full-time basis",
    value: 0,
  },
  {
    id: 9,
    title: "Income Sources",
    source: "Financial markets",
    description:
      "Income derived from holding or trading financial market instruments such as stocks, bonds, etc.",
    value: 0,
  },
  {
    id: 10,
    title: "Income Sources",
    source: "Other",
    description:
      "Other sources of income not included in the available selections",
    value: 0,
    explanation: "",
  },
]);

const selectedSources = ref([]);



const toggleSelection = (id) => {
  const index = selectedSources.value.indexOf(id);
  if (index > -1) {
    selectedSources.value.splice(index, 1);
  } else {
    selectedSources.value.push(id);
  }
};

const checkIncome = () => {
  if (selectedSources.value.includes(10) && otherIncome.value == "") {
    isVisible.value = true;
    modalType.value = "others";
    setTimeout(() => {
      inputRefOtherIncome.value.focus();
    }, 300);
  } else {
    router.push({ name: "anualincome" });
  }
};
</script>
<style scoped>
/* Slide-up and slide-down animations */
@keyframes slide-up {
  0% {
    transform: translateY(100%); /* Start from the bottom (hidden) */
  }
  100% {
    transform: translateY(0); /* End at the current position */
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0); /* Start at the current position */
  }
  100% {
    transform: translateY(100%); /* Move to the bottom (hidden) */
  }
}

/* Applying slide-up animation when entering */
.slide-fade-enter-active {
  animation: slide-up 0.5s ease-out;
}

/* Applying slide-down animation when leaving */
.slide-fade-leave-active {
  animation: slide-down 0.5s ease-in;
}
.backdrop-blur-container {
  width: 100%;
  height: 100%;
  z-index: 1;
  backdrop-filter: blur(0px); /* Apply the blur effect */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  /* Additional styles */
  position: absolute; /* To position it as needed */
  top: 0;
  left: 0;
}
</style>
