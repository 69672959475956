<template>
    <!-- <div class="flex flex-col gap-[24px]">
        <inputForm :label="'Full name'"></inputForm>
        <inputForm :label="'Email address'" type="email"></inputForm>
        <inputForm :label="'Password'" type="password"></inputForm>
        <btnForm>Create an account</btnForm>
    </div> -->

    <phoneContainer>
        <MobileNavbar></MobileNavbar>
        <div class="bg-white h-full flex justify-center items-center p-[16px]  flex-col gap-[16px]">
            <BackNav></BackNav>

            <div class="absolute w-full px-[16px] top-[90px]">
                <div class="mb-[24px]">
                    <headerForm>
                        <template #title>Enter your mobile number</template>
                        <template #subtitle>A verification code will be sent to your mobile number </template>
                    </headerForm>
                </div>
                <div class="mt-[70px] ">
                    <IntlTelInput id="inputNumber" :options="{
                        initialCountry: 'us',
                    }" />
                </div>
            </div>

            <div class="w-full  mb-[16px]">
                <btnForm :btn-disabled="!disabledBUtton" class="small-pulse" @click="proceedtoCode2()">Continue</btnForm>
            </div>

        </div>
    </phoneContainer>

</template>

<script setup>
import phoneContainer from '@/components/container/PhoneContainer.vue'
import MobileNavbar from '@/components/mini_components/MobileNavbar.vue';
import BackNav from '@/components/mini_components/BackNav.vue';
import headerForm from '@/components/form/header-form.vue';
import btnForm from "@/components/form/btn-form.vue";
import pincodeForm from '@/components/form/pincode-form.vue';
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import IntlTelInput from "intl-tel-input/vueWithUtils";
import "intl-tel-input/styles";
import { useRouter } from 'vue-router';
import { fa } from 'intl-tel-input/i18n';
const router = useRouter()
const inputValue = ref('');
const disabledBUtton = ref(false)
const proceedtoCode2 = () =>{
    router.push('/codeverification?id=1')
}
onMounted(() => {
  // Select the input field once mounted
  const inputElement = document.querySelector('#inputNumber'); // Adjust selector as needed

  if (inputElement) {
    // Watch the input's value and update `inputValue`
    inputElement.addEventListener('input', () => {
      inputValue.value = inputElement.value;
    });
  }
});
// Watch the `inputValue` for changes and check if it's empty
watch(inputValue, (newValue) => {
  if (newValue === '') {
    disabledBUtton.value = false
  } else {
    disabledBUtton.value = true
  }
});
</script>

<style scoped>
.iti__tel-input:focus {
    background-color: white;
    caret-color: #007bff;
    outline: none;
}

.iti__tel-input {
    transition: all 0.3s ease;
}
</style>